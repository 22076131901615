import React, { useState } from "react";
import { Layout, Menu } from "antd";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    LogoutOutlined,
    BarChartOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Navigation.module.css";
import { DashBookingSmallLogo, DashBookingLogo } from "./DashbookingLogo";
import { useDispatch } from "react-redux";
import { logout } from "../redux/actions/auth/auth";
const { Header, Sider, Content } = Layout;
const Navigation = ({ children }) => {
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(true);
    const toggle = () => {
        setCollapsed(!collapsed);
    };
    let navigate = useNavigate();

    const handleLogout = () => {
        dispatch(logout())
        return navigate("/")
    }
    return (
        <Layout>
            <Sider trigger={null} collapsible collapsed={collapsed}>
                <div className={styles.logo}>
                    {collapsed ? <DashBookingSmallLogo /> : <DashBookingLogo />}
                </div>
                <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
                    <Menu.Item key="1" icon={<BarChartOutlined />}>
                        <Link to="/">Dashboard</Link>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<UserOutlined />}>
                        <Link to="/profile">Profile</Link>
                    </Menu.Item>
                    <Menu.Item
                        key="3"
                        icon={<LogoutOutlined />}
                        onClick={() => handleLogout()}
                    >
                        Logout
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout className={styles.siteLayout}>
                <Header className={styles.header}>
                    {React.createElement(
                        collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                        {
                            className: "trigger",
                            onClick: () => toggle(),
                        }
                    )}
                </Header>
                <Content className={styles.contentContainer}>
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
};

export default Navigation;
