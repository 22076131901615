import React, {useEffect} from "react";
import { Form, Input, Button, Alert, Row, Col } from "antd";
import { LoginOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {ErrorMessage} from "../../components/common/ErrorMessage";
import { auth } from "../../redux/actions/auth/auth";
const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};
let currentYear = new Date();
const Login = () => {
    const dispatch = useDispatch();
    const { loading, error, message } = useSelector((state) => state.auth);

    const onFinish = (formData) => {
        dispatch(auth(formData));
    };

    useEffect(() => {
        if (error || message) {
            return ErrorMessage(error, message);
        }
    }, [error, message]);
    return (
        <Row
            justify="center"
            style={{ overflow: "scroll", height: "100%", padding: "20px 10px" }}
        >
            <Col
                xs={24}
                sm={24}
                md={16}
                lg={14}
                xl={10}
                xxl={8}
                style={{ marginTop: "50px" }}
            >
                <Form
                    {...layout}
                    name="basic"
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    size="large"
                    className="new-login-form"
                >
                    <h1>Login</h1>
                    <Form.Item
                        label="Username"
                        name="email"
                        rules={[
                            {
                                required: true,
                                type: "email",
                                message: "Please input your valid username!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Please input your password!",
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        style={{
                            width: "100%",
                            textAlign: "center",
                        }}
                    >
                        <Button
                            type="primary"
                            shape="round"
                            icon={<LoginOutlined />}
                            style={{ width: "150px" }}
                            htmlType="submit"
                            loading={loading}
                        >
                            Login
                        </Button>
                    </Form.Item>
                </Form>
                {error && (
                    <Alert
                        message="Error"
                        description={error}
                        type="error"
                        showIcon
                    />
                )}
                <div
                    // className="copy-right-footer"
                    style={{
                        width: "100%",
                        textAlign: "center",
                        marginTop: "20px",
                    }}
                >
                    <p style={{ margin: "0px" }}>
                        {currentYear.getFullYear()} &copy; Dash Booking Inc.
                    </p>
                    <a
                        href="https://home.dashbooking.com/partners-terms-and-conditions.html"
                        rel="noreferrer"
                        target="_blank"
                    >
                        Terms and Policy
                    </a>
                </div>
            </Col>
        </Row>
    );
};

export default Login;
