import React from "react";
import { useSelector } from "react-redux";
import FormButtons from "./FormButtons";
import ApplicationOutline from "../ViewApplication/ApplicationOutline";

export default function SubmissionForm(props) {
    const { application } = useSelector((state) => state.application);

    return (
        <div>
            <FormButtons  currentStep={props.currentStep}
                previousStep={props.previousStep}
                nextStep={props.nextStep}
                formRef={null}
                saveForm={null}
            />
            <ApplicationOutline application={application} />
        </div>
    );
};
