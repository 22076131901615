// import API from "../../../utils/API/API";

import {
    LOGIN_STARTED,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT,
    UPDATE_PASSWORD_STARTED,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/auth/auth";

var user;
if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"));
}

const initialState = {
    user: user,
    isAuthenticated: !!user,
    loading: false,
    error: null,
    message: null,
};

export default function auth(state = initialState, action) {
    switch (action.type) {
        case UPDATE_PASSWORD_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                message: "New password has been saved!",
            };
        case UPDATE_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case LOGIN_STARTED:
            return {
                ...state,
                loading: true,
            };
        case LOGIN_SUCCESS:
            const userData = action.payload.data.account ?? null;
            localStorage.setItem("user", JSON.stringify(userData));

            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                user: action.payload.data.account,
            };
        case LOGIN_FAILURE:
            localStorage.clear();
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error: "Incorrect username or password",
                user: null,
            };
        case LOGOUT:
            localStorage.clear();
            return {
                ...state,
                user: null,
                loading: false,
                isAuthenticated: false,
            };
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        default:
            return state;
    }
}
