import React from "react";
import { Form, Select, Button, Divider, Input } from "antd";
import { addComment } from "../../../redux/actions/application/application";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
const { Option } = Select;
export const ApplicationNotes = () => {
    const [form] = Form.useForm();
    const { subLoading } = useSelector((state) => state.application);
    const { _id } = useSelector((state) => state.application.application);
    const dispatch = useDispatch();
    const onFinish = (values) => {
        const newComment = { ...values, id: _id };
        dispatch(addComment(newComment));
        form.resetFields();
    };
    return (
        <Form form={form} layout='vertical' onFinish={onFinish}>
            <h3>Add new comment</h3>
            <Form.Item name='type' label='Type' rules={[{ required: true }]}>
                <Select placeholder='Select a type of note' allowClear>
                    <Option value='Update Inquiry'>Update Inquiry</Option>
                    <Option value='More Information Required'>
                        More Information Required
                    </Option>
                    <Option value='Reply'>Reply</Option>
                    <Option value='Shipping Report'>Shipping Report</Option>
                    <Option value='Notification'>Notification</Option>
                    <Option value='Other'>Other</Option>
                </Select>
            </Form.Item>
            <Form.Item name='note' label="Comment" rules={[{ required: true, message:"Comment is required" }]}>
                <Input.TextArea  rows={4} />
            </Form.Item>

            <Form.Item>
                <Button
                    loading={subLoading}
                    type='primary'
                    style={{ borderRadius: "5px" }}
                    htmlType='submit'
                >
                    Submit
                </Button>
            </Form.Item>
            <Divider />
        </Form>
    );
};
