import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ElavonCancellationForm } from "./ApplicationCancelForms/ElavonCancellationForm";
import { FirstDataCancellationForm } from "./ApplicationCancelForms/FirstDataCancellationForm";
import { submitCancellationForm } from "../../../redux/actions/application/application";

export const ApplicationCancel = (props) => {
    const formRef = React.createRef();
    const { subLoading } = useSelector(state => state.application)
    const dispatch = useDispatch()
    const { application } = props;

    const handleSubmitCancelForm = (formValues) => {
        let formData = {}
        formData._id = application._id
        formData.formValues = formValues

        dispatch(submitCancellationForm(formData))
    };

    return (
        application.type === "First Data" ?
        <FirstDataCancellationForm
            application={application}
            formRef={formRef}
            handleSubmitCancelForm={handleSubmitCancelForm}
            subLoading={subLoading}
        />
        :
        <ElavonCancellationForm
            application={application}
            formRef={formRef}
            handleSubmitCancelForm={handleSubmitCancelForm}
            subLoading={subLoading}
        />
    );
};
