export const LOGOUT = "LOGOUT";

export const LOGIN = "LOGIN";
export const LOGIN_STARTED = "LOGIN_STARTED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const UPDATE_PASSWORD_STARTED = "UPDATE_PASSWORD_STARTED";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";

export const REMOVE_ERROR_MESSAGE ="REMOVE_ERROR_MESSAGE"