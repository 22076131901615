import { Row, Col, Button, Popconfirm } from "antd";
import {
    SaveOutlined,
    LeftOutlined,
    RightOutlined,
    SendOutlined,
    DeleteTwoTone,
} from "@ant-design/icons";
import { ErrorMessage } from "../../common/ErrorMessage";
import { useDispatch } from "react-redux";
import { submitApplicationToAdmin, deleteApplication } from "../../../redux/actions/application/application";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";


const FormButtons = (props) => {
    const { currentStep, formRef } = props;
    const {applicationSubmitted, applicationDeleted} = useSelector(state => state.application)
    const dispatch = useDispatch();
    let navigate = useNavigate();
 

    useEffect(() => {
        if(applicationDeleted || applicationSubmitted) {
            return navigate("/")
        }
      }, [applicationDeleted, applicationSubmitted]); // Only re-run the effect if params changes

    const { application } = useSelector((state) => state.application);
   
    const handleSubmitApplicationToAdmin = () => {
        dispatch(submitApplicationToAdmin(application._id));
    };
    


    const handleNextBtn = () => {
        formRef.current
            .validateFields()
            .then((values) => {
                // console.log(values)
                props.saveForm();
                // ErrorMessage(null, `${FormNames[currentStep]} form saved.`)
                props.nextStep();
            })
            .catch((info) => {
                if (info.errorFields.length === 1) {
                    info.errorFields[0].errors.forEach((errorMsg) => {
                        ErrorMessage(errorMsg, null);
                    });
                } else if (info.errorFields.length > 1) {
                    ErrorMessage(
                        "Please make sure the whole form is valid.",
                        null
                    );
                }
            });
    };

    return (
        <Row justify='space-between' style={{ marginBottom: 15, position:"sticky", backgroundColor:"white",  padding:"0px 10px 10px 10px", zIndex:"9", top:"0px" }}>
            <Col>
                {formRef && (
                    <Button
                        htmlType='button'
                        style={{backgroundColor:"#389e0d", color:"white", borderColor:"#52c41a", borderRadius:"5px"}}
                        icon={<SaveOutlined />}
                        onClick={() => props.saveForm()}
                    >
                        Save for Later
                    </Button>
                )}
                {!formRef && application.status === "Draft" && (
                    <Button
                        htmlType='button'
                        shape="round"
                        type='primary'
                        icon={<SendOutlined />}
                        onClick={() => handleSubmitApplicationToAdmin()}
                    >
                        Submit to Admin
                    </Button>
                )}

              
            </Col>
            <Col>
                <Button
                    // size="large"
                    htmlType='button'
                    onClick={() => props.previousStep()}
                    disabled={currentStep === 0}
                    style={{ marginRight: 5, borderRadius:"5px" }}
                >
                    <LeftOutlined /> Previous
                </Button>
                <Button
                    // size="large"
                    htmlType='submit'
                    type='primary'
                    onClick={() => handleNextBtn()}
                    disabled={currentStep === 6}
                    style={{borderRadius:"5px"}}
                >
                    Next <RightOutlined />
                </Button>
            </Col>
        </Row>
    );
};

export default FormButtons;
