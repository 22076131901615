import React from "react";
import { List, Tooltip, Popconfirm, Button } from "antd";
import moment from "moment";
import { DeleteTwoTone } from "@ant-design/icons";
import { useSelector } from "react-redux";
export const CommentList = (props) => {
    const { comments } = props;
    const {user} = useSelector(state => state.auth)
    return (
        <div>
            <List
                className='comment-list'
                header={`${comments.length} Comments`}
                itemLayout='vertical'
                dataSource={comments.sort(
                    (a, b) =>
                        new Date(b.date).getTime() - new Date(a.date).getTime()
                )}
                renderItem={(item) => {
                    // let contentState = JSON.parse(item.note);
                    // contentState = convertFromRaw(contentState);
                    // const editorState =
                    //     EditorState.createWithContent(contentState);

                    return (
                        <List.Item
                            key={item._id}
                            actions={[
                                <Tooltip
                                    title={moment(item.date).format(
                                        "MMM DD, yyyy hh:mma"
                                    )}
                                >
                                    <span>{moment(item.date).fromNow()}</span>
                                </Tooltip>,
                            ]}
                        >
                            <List.Item.Meta
                                title={(
                                    <>
                                        <span style={{ color: "#096dd9", marginRight: 5 }}>
                                            {item.type}
                                        </span>
                                        {user && (user.role==="admin" || (user.email === item.authorEmail)) && <Popconfirm
                                            title='Are you sure to delete this comment?'
                                            onConfirm={() => props.handleDeleteComment(item._id)}
                                            okText='Yes'
                                            cancelText='No'
                                            icon={<DeleteTwoTone twoToneColor='#f5222d' />}
                                        >
                                            <Button type='text' style={{marginLeft:"10px"}}>
                                                <DeleteTwoTone twoToneColor='#f5222d' />
                                            </Button>
                                        </Popconfirm>}
                                    </>
                                )}
                                description={
                                    <span>
                                        {item.authorName} - {item.authorEmail}
                                    </span>
                                }
                            />
                            {item.note}
                          
                        </List.Item>
                    );
                }}
            />
        </div>
    );
};
