import React from "react";
import { Drawer, Form, Input, Select, Button, DatePicker, Affix } from "antd";
import { formatPhoneNumber } from "../../utils/FormatPhoneNumber/FormatPhoneNumber";
import { ClearOutlined } from "@ant-design/icons";
import { ApplicationStatus } from "../Application/ApplicationStatus";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { SaleRepDropdown } from "../Application/EditApplication/SaleRepDropdown";
import queryString from "query-string";
const { RangePicker } = DatePicker;
const { Option } = Select;

export default function DashboardFilter(props) {
    const formRef = React.createRef();
    const { isMobile, isDrawerVisible } = props;
    let [searchParams] = useSearchParams();
    const { user } = useSelector((state) => state.auth);
    let filterFormFields = {
        mid: null,
        dbaName: null,
        type: null,
        province: null,
        createdBy: null,
        status: null,
        fullName: null,
        purchaseType: null,
        ownerPhone: null,
        createdAt: null
    };

    for (const [key, value] of searchParams.entries()) {
        filterFormFields[key] = value;
    }

    const handlePhoneChange = (e) => {
        const formatPhone = formatPhoneNumber(e.target.value);

        formRef.current.setFieldsValue({
            ownerPhone: formatPhone,
        });
    };

    const clearForm = () => {
        formRef.current.setFieldsValue({
            mid: null,
            dbaName: null,
            type: null,
            province: null,
            saleRepEmail: null,
            saleRepPhone: null,
            createdBy: null,
            status: null,
            fullName: null,
            purchaseType: null,
            ownerPhone: null,
        });
        props.handleSubmitFilter({});
    };

    return (
        <Drawer
            title="Filter applications"
            onClose={() => props.setIsDrawerVisible(false)}
            visible={isDrawerVisible}
            // bodyStyle={{  }}
            width={isMobile ? "100%" : 378}
            extra={
                <>
                    <Button
                        onClick={() => clearForm()}
                        style={{ borderRadius: 5, marginRight: 5 }}
                        icon={<ClearOutlined />}
                        danger
                    />
                </>
            }
        >
            <Form
                ref={formRef}
                layout="vertical"
                onFinish={props.handleSubmitFilter}
                initialValues={filterFormFields}
            >
                <Form.Item name="mid" label="MID">
                    <Input placeholder="Enter MID" allowClear />
                </Form.Item>
                <Form.Item name="dbaName" label="DBA Name">
                    <Input placeholder="Enter DBA Name" allowClear />
                </Form.Item>
                {user && user.role === "admin" && (
                    <SaleRepDropdown formRef={formRef} user={user} />
                )}
                <Form.Item name="type" label="Application Type">
                    <Select
                        allowClear
                        optionLabelProp="value"
                        placeholder="Select Application Type"
                    >
                        <Option value="First Data">
                            <img
                                src="/assets/logos/firstdata.svg"
                                height={50}
                                alt="First Data"
                            />
                        </Option>
                        <Option value="Elavon">
                            <img
                                src="/assets/logos/elavon.svg"
                                height={50}
                                alt="Elavon"
                            />
                        </Option>
                    </Select>
                </Form.Item>
                <Form.Item name="purchaseType" label="Rent/Lease">
                    <Select
                        allowClear
                        optionLabelProp="value"
                        placeholder="Select purchase type"
                    >
                        <Option value={null}>

                        </Option>
                        <Option value="Lease">
                            Lease
                        </Option>
                        <Option value="Rent">
                            Rent
                        </Option>
                    </Select>
                </Form.Item>
                <Form.Item name="province" label="Province">
                    <Select allowClear placeholder="Select Province">
                        <Option value="Alberta">Alberta</Option>
                        <Option value="British Columbia">
                            British Columbia
                        </Option>
                        <Option value="Manitoba">Manitoba</Option>
                        <Option value="Ontario">Ontario</Option>
                        <Option value="Quebec">Quebec</Option>
                        <Option value="Saskatchewan">Saskatchewan</Option>
                        <Option value="Prince Edward Island">
                            Prince Edward Island
                        </Option>
                        <Option value="Nova Scotia">Nova Scotia</Option>
                        <Option value="New Brunswick">New Brunswick</Option>
                        <Option value="Newfoundland and Labrador">
                            Newfoundland and Labrador
                        </Option>
                        <Option value="Northwest Territories">
                            Northwest Territories
                        </Option>
                        <Option value="Yukon">Yukon</Option>
                        <Option value="Nunavut">Nunavut</Option>
                    </Select>
                </Form.Item>
                {/* {user && user.role === "admin" && <Form.Item
                    name="createdBy"
                    label="Created By"
                >
                    <Input
                        placeholder="Enter Created By"
                        allowClear
                    />
                </Form.Item>} */}


                <Form.Item name="status" label="Status">
                    <Select allowClear placeholder="Select Status">
                        <Option value="Draft">
                            <ApplicationStatus status={"Draft"} />
                        </Option>
                        <Option value="Pending">
                            <ApplicationStatus status={"Pending"} />
                        </Option>
                        <Option value="Approved">
                            <ApplicationStatus status={"Approved"} />
                        </Option>
                        <Option value="Shipping">
                            <ApplicationStatus status={"Shipping"} />
                        </Option>
                        <Option value="Submitted to Admin">
                            <ApplicationStatus status={"Submitted to Admin"} />
                        </Option>
                        <Option value="Sent Out for Signature">
                            <ApplicationStatus
                                status={"Sent Out for Signature"}
                            />
                        </Option>
                        <Option value="Submitted to Supplier">
                            <ApplicationStatus
                                status={"Submitted to Supplier"}
                            />
                        </Option>
                        <Option value="Declined">
                            <ApplicationStatus status={"Declined"} />
                        </Option>
                        <Option value="Cancel Pending">
                            <ApplicationStatus status={"Cancel Pending"} />
                        </Option>
                        <Option value="Canceled">
                            <ApplicationStatus status={"Canceled"} />
                        </Option>
                    </Select>
                </Form.Item>
                <Form.Item name="fullName" label="Owner Name">
                    <Input allowClear placeholder="Owner Full Name" />
                </Form.Item>
                <Form.Item name="ownerPhone" label="Owner Phone">
                    <Input
                        onChange={(e) => handlePhoneChange(e)}
                        placeholder="(xxx) xxx-xxxx"
                        allowClear
                    />
                </Form.Item>
                <Form.Item
                    name="createdAt"
                    label="Period"
                    style={{ marginBottom: "12px" }}
                >
                    <RangePicker />
                </Form.Item>
                <Affix offsetBottom={10}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        // onClick={() => props.handleSubmitFilter()}
                        style={{ borderRadius: 5, width: "100%" }}
                    >
                        Submit Filter
                    </Button>
                </Affix>
            </Form>
        </Drawer>
    );
}
