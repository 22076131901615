import React, { useEffect, useState } from "react";
import {
    Form,
    Row,
    Col,
    Select,
    Input,
    Button,
    Divider,
    // Tooltip,
    // DatePicker,
} from "antd";
import FormButtons from "./FormButtons";
import { MinusCircleTwoTone, PlusOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { saveApplication } from "../../../redux/actions/application/application";

const { Option } = Select;

const FIRST_DATA_EQUIPMENT_LIST = [
    "Clover Flex 3nd Gen Wifi & LTE (New)",
    // "Clover Flex 2nd Gen WiFi",
    "Clover Mini 3 Wifi & LTE (New)",
    "Clover Station Duo",
    "Clover Station Solo",
    "Clover Mini Tethering Cable",
    "Gateway",
];

const ELAVON_EQUIPMENT_LIST = ["NewLand N910 CAN", "PAX A920", "Poynt C CAN", "Move 5000", "Converge"];

export default function EquipmentForm(props) {
    const formRef = React.createRef();
    const [isFormUpdated, setIsFormUpdated] = useState(false);
    const { application } = useSelector((state) => state.application);
    const dispatch = useDispatch();

    let equipments = [
        {
            name: null,
            unitPrice: null,
        },
    ];

    useEffect(() => {
        if (application.equipments.length > 0) {
            equipments = application.equipments;
            formRef.current.setFieldsValue({ equipments });
        }
    }, []);

    const saveForm = () => {
        if (isFormUpdated && application._id) {
            let formData = formRef.current.getFieldsValue();
            formData._id = application._id;
            setIsFormUpdated(false);
            dispatch(saveApplication(formData));
        }
    };

    return (
        <Form
            ref={formRef}
            layout='vertical'
            onFinish={saveForm}
            requiredMark='Required'
            initialValues={{ equipments }}
            onFieldsChange={() => setIsFormUpdated(true)}
            scrollToFirstError
        >
            <FormButtons
                currentStep={props.currentStep}
                previousStep={props.previousStep}
                nextStep={props.nextStep}
                saveForm={saveForm}
                formRef={formRef}
            />
            <Row justify='space-between' style={{ width: "100%" }}>
                <Form.List name='equipments'>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(
                                ({ key, name, ...restField }, index) => (
                                    <Col
                                        xs={24}
                                        sm={24}
                                        md={11}
                                        lg={11}
                                        xl={11}
                                        key={key}
                                    >
                                        <Row
                                            justify='space-between'
                                            align='middle'
                                        >
                                            <Col>
                                                <h4>Equipment {index + 1}</h4>
                                            </Col>
                                            <Col>
                                                <MinusCircleTwoTone
                                                    twoToneColor='#ff4d4f'
                                                    onClick={() => remove(name)}
                                                />
                                            </Col>
                                        </Row>
                                        <Input.Group compact>
                                            <Form.Item
                                                {...restField}
                                                name={[name, "name"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Missing equipment name.",
                                                    },
                                                ]}
                                                style={{ width: "75%" }}
                                            >
                                                <Select
                                                    placeholder='Name'
                                                    onChange={(value) => {
                                                        let formData =
                                                            formRef.current.getFieldsValue();
                                                        let equipments =
                                                            formData.equipments;

                                                        if (
                                                            value ===
                                                            "Clover Mini Tethering Cable"
                                                        ) {
                                                            (equipments[
                                                                index
                                                            ].purchaseType =
                                                                "P"),
                                                                (equipments[
                                                                    index
                                                                ].unitPrice = 20);
                                                        }
                                                        formRef.current.setFieldsValue(
                                                            {
                                                                equipments:
                                                                    equipments,
                                                            }
                                                        );
                                                    }}
                                                >
                                                    {application.type ===
                                                        "First Data" &&
                                                        FIRST_DATA_EQUIPMENT_LIST.map(
                                                            (equipment) => {
                                                                return (
                                                                    <Option
                                                                        key={
                                                                            equipment
                                                                        }
                                                                        value={
                                                                            equipment
                                                                        }
                                                                    >
                                                                        {
                                                                            equipment
                                                                        }
                                                                    </Option>
                                                                );
                                                            }
                                                        )}
                                                    {application.type ===
                                                        "Elavon" &&
                                                        ELAVON_EQUIPMENT_LIST.map(
                                                            (equipment) => {
                                                                return (
                                                                    <Option
                                                                        key={
                                                                            equipment
                                                                        }
                                                                        value={
                                                                            equipment
                                                                        }
                                                                    >
                                                                        {
                                                                            equipment
                                                                        }
                                                                    </Option>
                                                                );
                                                            }
                                                        )}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, "purchaseType"]}
                                                style={{
                                                    width: "24%",
                                                    minWidth: 90,
                                                }}
                                            // rules={[{ required: true, message: 'Purchase type is required.' }]}
                                            >
                                                <Select placeholder='Purchase Type'>
                                                    <Option value='Rent'>
                                                        Rent
                                                    </Option>
                                                    <Option value='Lease'>
                                                        Lease
                                                    </Option>
                                                    <Option value='Purchase'>
                                                        Purchase
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        </Input.Group>
                                        <Input.Group compact>
                                            <Form.Item
                                                {...restField}
                                                name={[name, "unitPrice"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Unit price is required.",
                                                    },
                                                ]}
                                                style={{ width: "75%" }}
                                            >
                                                <Input
                                                    placeholder='Unit Price'
                                                    prefix='$'
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                {...restField}
                                                name={[name, "quantity"]}
                                                initialValue='1'
                                                style={{
                                                    width: "24%",
                                                    minWidth: 60,
                                                }}
                                            // rules={[{ required: true, message: 'Missing purchase type.' }]}
                                            >
                                                <Select>
                                                    <Option value='1'>1</Option>
                                                    <Option value='2'>2</Option>
                                                    <Option value='3'>3</Option>
                                                    <Option value='4'>4</Option>
                                                    <Option value='5'>5</Option>
                                                    <Option value='6'>6</Option>
                                                    <Option value='7'>7</Option>
                                                    <Option value='8'>8</Option>
                                                    <Option value='9'>9</Option>
                                                    <Option value='10'>
                                                        10
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        </Input.Group>
                                        <Form.Item
                                            {...restField}
                                            label='Note'
                                            name={[name, "note"]}
                                        >
                                            <Input.TextArea placeholder='Note' />
                                        </Form.Item>
                                        {application.status !== "Draft" && (
                                            <Input.Group>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "terminalId"]}
                                                    label='Terminal Id'
                                                >
                                                    <Input placeholder='Terminal ID' />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[
                                                        name,
                                                        "serialNumber",
                                                    ]}
                                                    label='Serial Number'
                                                >
                                                    <Input placeholder='Serial Number' />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    label={"Tracking Number "}
                                                    name={[name, "trackingNum"]}
                                                >
                                                    <Input placeholder='Tracking Number' />
                                                </Form.Item>

                                                {/* <Form.Item
                                                    {...restField}
                                                    label="Shipped Date"
                                                    name={[name, "shippedDate"]}
                                                >
                                                    <DatePicker 
                                                        onChange={(value) => console.log(value)}
                                                        placeholder="Shipped Date"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                </Form.Item> */}
                                            </Input.Group>
                                        )}
                                        <Divider />
                                    </Col>
                                )
                            )}
                            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                <Button
                                    type='dashed'
                                    onClick={() => add()}
                                    block
                                    icon={<PlusOutlined />}
                                >
                                    Add equipment
                                </Button>
                            </Col>
                        </>
                    )}
                </Form.List>
            </Row>
        </Form>
    );
}
