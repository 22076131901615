import React from "react";
import { Table, Pagination } from "antd";
import { ApplicationStatus } from "../Application/ApplicationStatus";
import { Link } from "react-router-dom";
import moment from "moment";

const { Column } = Table;

export default function DashboardTable(props) {
    const { loading, applicationList, count, currentPage } = props;
    
    return (
        <>
        <Table
            dataSource={applicationList}
            pagination={false}
            rowKey="_id"
            loading={loading}
            width="100%"
        >
            <Column
                title="DBA Name"
                key="dbaName"
                dataIndex={("_id", "dbaName")}
                render={(_, record) => {
                    return (
                        <Link
                            to={`/application/view?id=${record._id}`}
                            style={{ margin: "5px" }}
                        >
                            {record.dbaName}
                        </Link>
                    );
                }}
            />
            <Column title="MID" key="mid" dataIndex="mid" />
            <Column title="Type" key="type" dataIndex="type" />

            <Column
                title="Province"
                key="province"
                dataIndex="dbaAddress"
                render={(dbaAddressObject) =>
                    dbaAddressObject && <>{dbaAddressObject.province}</>
                }
            />
            <Column title="By" key="createdBy" dataIndex="createdBy" />
            <Column
                title="Status"
                dataIndex="status"
                key="status"
                render={(status) => {
                    return <ApplicationStatus status={status} />;
                }}
            />
            <Column
                key="applicationFee"
                title="App Fee"
                dataIndex={"applicationFee"}
            />
            <Column
                key="createdAt"
                title="Date"
                dataIndex={"createdAt"}
                render={(createdAt) => {
                    return <div> {moment(createdAt).format("MMM DD")}</div>;
                }}
            />
        </Table>
        {count> 0 && <Pagination defaultCurrent={currentPage} total={count} pageSize={10} onChange={(page) => props.goToPage(page)} />}
        </>
    );
}
