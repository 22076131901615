import React, { useState } from "react";
import { Form, DatePicker, Button, Select } from "antd";
import { ApplicationStatus } from "../ApplicationStatus";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { updateStatus } from "../../../redux/actions/application/application";
import { ApplicationHistory } from "./ApplicationHistory";

const { Option } = Select;
export const ApplicationStatusHistory = () => {
    const [isSubmitEnable, setIsSubmitEnable] = useState(false)
    const dispatch = useDispatch()
    const { application, subLoading } = useSelector(state => state.application)
    const { user } = useSelector(state => state.auth)
    const onFinish = (values) => {
        values.date = values.date.toDate()
        values.id = application._id
        dispatch(updateStatus(values))
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <div>
            {["admin", "developer"].includes(user.role) && <Form
                layout='vertical'
                initialValues={{ status: application.status, date: moment() }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onValuesChange={() => setIsSubmitEnable(true)}
            >
                <Form.Item
                    label='Status'
                    name='status'
                    rules={[{ required: true, message: "Status is required" }]}
                >
                    <Select allowClear placeholder='Select Status'>
                        <Option value='From Amigo'>
                            <ApplicationStatus status={"From Amigo"} />
                        </Option>
                        <Option value='Draft'>
                            <ApplicationStatus status={"Draft"} />
                        </Option>
                        <Option value='Pending'>
                            <ApplicationStatus status={"Pending"} />
                        </Option>
                        <Option value='Approved'>
                            <ApplicationStatus status={"Approved"} />
                        </Option>
                        <Option value='Shipping'>
                            <ApplicationStatus status={"Shipping"} />
                        </Option>
                        <Option value='Submitted to Admin'>
                            <ApplicationStatus status={"Submitted to Admin"} />
                        </Option>
                        <Option value='Sent Out for Signature'>
                            <ApplicationStatus status={"Sent Out for Signature"} />
                        </Option>
                        <Option value='Submitted to Supplier'>
                            <ApplicationStatus status={"Submitted to Supplier"} />
                        </Option>
                        <Option value='Declined'>
                            <ApplicationStatus status={"Declined"} />
                        </Option>
                        <Option value='Cancel Pending'>
                            <ApplicationStatus status={"Cancel Pending"} />
                        </Option>
                        <Option value='Canceled'>
                            <ApplicationStatus status={"Canceled"} />
                        </Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label='Date'
                    name='date'
                    rules={[{ required: true, message: "Date is required" }]}
                >
                    <DatePicker format="MMM DD, YYYY" />
                </Form.Item>

                <Form.Item>
                    <Button disabled={!isSubmitEnable} loading={subLoading} type='primary' style={{ borderRadius: "5px" }} htmlType='submit'>
                        Udpate
                    </Button>
                </Form.Item>
            </Form>}
            <ApplicationHistory history={application.history} />
        </div>
    );
};
