import React from 'react'
import {Tag} from 'antd'
export const ApplicationStatus = (props) => {
    let color = "#bfbfbf"
    const {status} = props
    if(status ===  "From Amigo") {
        color = "#0050b3"
    }
    if(status ===  "Pending") {
        color = "#fadb14"
    }
    if(status ===  "Approved") {
        color = "#52c41a"
    }
    if(status ===  "Shipping") {
        color = "#ffc53d"
    }
    if(status ===  "Submitted to Admin") {
        color = "#1890ff"
    }
    if(status ===  "Sent Out for Signature") {
        color = "#722ed1"
    }
    if(status ===  "Submitted to Supplier") {
        color = "#8cc8ff"
    }
    if(status ===  "Declined") {
        color = "#f5222d"
    }
    if(status ===  "Cancel Pending") {
        color = "#ff7a45"
    }
    if(status ===  "Canceled") {
        color = "#000000"
    }
  return (
   <Tag color={color} >{status}</Tag>
   
  )
}
