import React from "react";
import { Form, Row,  Button, Input, Switch, Space } from "antd";
import { MinusCircleTwoTone, PlusOutlined } from "@ant-design/icons";

const { TextArea } = Input;

export const FirstDataCancellationForm = (props) => {
    const { application, formRef, subLoading } = props;


    const submit = (formValues) => {
        props.handleSubmitCancelForm(formValues);
    };

    return (
        <Form
            ref={formRef}
            layout="vertical"
            onFinish={submit}
            initialValues={{
                saleRepEmail: application.saleRepEmail,
                mid: application.mid,
                dba: application.dbaName,
                equipments: application.equipments,
                shippingAddress:
                    Object.values(application.dbaAddress).join(", ") || "",
                emailAddress: application.email,
            }}
            scrollToFirstError
        >
            <Form.Item
                label="DBA"
                name="dba"
                rules={[
                    {
                        required: true,
                        message: "DBA name is required",
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="MID"
                name="mid"
                rules={[
                    {
                        required: true,
                        message: "MID is required",
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Reason for Closure"
                name="reasonForClosure"
                rules={[
                    {
                        required: true,
                        message: "Reason for Closure is required",
                    },
                ]}
            >
                <TextArea />
            </Form.Item>
        
            <Form.Item
                label="Email Address"
                name="emailAddress"
                rules={[
                    {
                        required: true,
                        message: "Email is required",
                    },
                    {
                        type: "email",
                        message: "Please enter a valid email address.",
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.List name="equipments">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field) => (
                            <Row key={field.key}>
                                <Space>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, curValues) =>
                                            prevValues.equipments !==
                                            curValues.equipments
                                        }
                                    >
                                        {() => (
                                            <Form.Item
                                                {...field}
                                                style={{width:"300px"}}
                                                label="Equipment"
                                                name={[field.name, "name"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Missing equipment name",
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        )}
                                    </Form.Item>

                                    <Form.Item
                                        {...field}
                                       
                                        label="Serial Number"
                                        name={[field.name, "serialNumber"]}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Missing serial number",
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <MinusCircleTwoTone
                                        twoToneColor="#ff4d4f"
                                        onClick={() => remove(field.name)}
                                    />
                                </Space>
                            </Row>
                        ))}

                        

                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                            >
                                Add equipment
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>

            <Form.Item label="Shipping Address" name="shippingAddress">
                <Input />
            </Form.Item>

            <Form.Item label="ETF" name="etf" valuePropName="checked">
                <Switch />
            </Form.Item>
            <Form.Item label="Refund Clarification" name="refundClarification">
                <TextArea />
            </Form.Item>

            <Form.Item label="TID" name="tid">
                <Input />
            </Form.Item>

            {/* <Form.List name="equipments">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }, index) => (
                            <div key={"header " + key}>
                                <Row justify="space-between" align="middle">
                                    <Col>
                                        <h4>
                                            Equipment {index + 1}{" "}
                                            <MinusCircleTwoTone
                                                twoToneColor="#ff4d4f"
                                                onClick={() => {
                                                    remove(name);
                                                    equipments.splice(index, 1);
                                                    isSubmitDisabledArray.splice(
                                                        index,
                                                        1
                                                    );
                                                }}
                                            />
                                        </h4>
                                    </Col>
                                </Row>

                                <Row key={key} style={{ marginBottom: 20 }}>
                                    <Col span={12}>
                                        <Input
                                            placeholder="Equipment"
                                            onChange={(e) => {
                                                let tempEquipments = equipments;
                                                tempEquipments[
                                                    index
                                                ].equipment = e.target.value;
                                                setEquipments(tempEquipments);

                                                let newSubmitDisabledArray = [
                                                    ...isSubmitDisabledArray,
                                                ];
                                                newSubmitDisabledArray[index] =
                                                    e.target.value
                                                        ? false
                                                        : true;
                                                setIsSubmitDisabledArray(
                                                    newSubmitDisabledArray
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <Input
                                            placeholder="Serial Number"
                                            onChange={(e) => {
                                                let tempEquipments = equipments;
                                                tempEquipments[
                                                    index
                                                ].serialNumber = e.target.value;
                                                setEquipments(tempEquipments);

                                                let newSubmitDisabledArray = [
                                                    ...isSubmitDisabledArray,
                                                ];
                                                newSubmitDisabledArray[index] =
                                                    e.target.value
                                                        ? false
                                                        : true;
                                                setIsSubmitDisabledArray(
                                                    newSubmitDisabledArray
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        ))}
                        <Row style={{ margin: "10px 0" }}>
                            <Button
                                type="dashed"
                                onClick={() => {
                                    add();
                                    let tempEquipments = [...equipments];
                                    tempEquipments.push({
                                        equipment: "",
                                        serialNumber: "",
                                    });
                                    setEquipments(tempEquipments);

                                    let newSubmitDisabledArray = [
                                        ...isSubmitDisabledArray,
                                    ];
                                    newSubmitDisabledArray.push(true);
                                    setIsSubmitDisabledArray(
                                        newSubmitDisabledArray
                                    );
                                }}
                                block
                                icon={<PlusOutlined />}
                            >
                                Add equipment
                            </Button>
                        </Row>
                    </>
                )}
            </Form.List> */}

           
            <Form.Item>
                <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    style={{ borderRadius: "5px", width: "100%" }}
                    loading={subLoading}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};
