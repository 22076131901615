import React, {useState, useEffect} from "react";
import { Form, Row, Col, Switch, Input, Select } from "antd";
import FormButtons from "./FormButtons";
import { useSelector, useDispatch } from "react-redux";
import { saveApplication } from "../../../redux/actions/application/application";

const { Option } = Select;

export default function FundingForm(props) {
    const formRef = React.createRef();
    const { application } = useSelector((state) => state.application);
    const dispatch = useDispatch();

    const fundingForm = {
        fundingRun: "11:30 PM",
        autoCloseTime: "11:30 PM",
        commentToCreditOfficer: "",
        autoSettle: false,
    }

    for (const [key, value] of Object.entries(fundingForm)) {
        if (application.hasOwnProperty(key)) {
            fundingForm[key] = application[key];
        }
    }

    const [isFormUpdated, setIsFormUpdated] = useState(false)   

    const saveForm = () => {
        if (isFormUpdated && application._id) {
            let formData = formRef.current.getFieldsValue();
            console.log(formData)
            formData._id = application._id;
            setIsFormUpdated(false)
            dispatch(saveApplication(formData));
           
        }
    };

    return (
        <Form
            ref={formRef}
            layout="vertical"
            onFinish={saveForm}
            requiredMark="Required"
            initialValues={fundingForm}
            onFieldsChange={() => setIsFormUpdated(true)}
          >
            <FormButtons
                currentStep={props.currentStep}
                previousStep={props.previousStep}
                nextStep={props.nextStep}
                formRef={formRef}
                saveForm={saveForm}
            />
            <Row
                justify="space-between"
                style={{ width: "100%" }}
            >
                <Col span={24}>
                    <Form.Item
                        label="Funding Run"
                        name="fundingRun"
                        required
                        rules={[
                            {
                                required: true,
                                message: "Funding run is required.",
                            },
                        ]}
                    >
                        <Select placeholder="Funding run">
                            <Option value="7:30 PM">7:30 PM</Option>
                            <Option value="9:30 PM">9:30 PM</Option>
                            <Option value="11:30 PM">11:30 PM</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Auto Close Time"
                        name="autoCloseTime"
                        required
                        rules={[
                            {
                                required: true,
                                message: "Auto close time is required.",
                            },
                        ]}
                    >
                        <Select placeholder="Auto close time">
                            <Option value="7:30 PM">7:30 PM</Option>
                            <Option value="9:30 PM">9:30 PM</Option>
                            <Option value="11:30 PM">11:30 PM</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Auto settle"
                        name="autoSettle"
                        valuePropName="checked"
                    >
                        <Switch  />
                    </Form.Item>
                    <Form.Item
                        label="Comment to Credit Officer"
                        name="commentToCreditOfficer"
                    >
                        <Input.TextArea rows={4} placeholder="HTS $1000x12 PER YEAR or any promotion" />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};