/**
 * Replace input string with formated phone number
 * @param {*} value
 */
export function formatPhoneNumber(value) {
    const phoneRegex = /^\(?([2-9][0-8][0-9])\)? ?([2-9][0-9]{2})[-.●]?([0-9]{4})$/;
    value = value.replace(" ", "");
    value = value.replace(phoneRegex, "($1) $2-$3");

    value = value.replace("+1", "");
    if (value.length > 9) {
        value = value.slice(0, 14);
    }

    return value;
}

/**
 * Check if the input string is a valid phone number
 * formated: (555)-555-5555
 * @param {*} value
 */
export function validatePhoneNumber(value) {
    const phoneRegex = /^\(?([2-9][0-8][0-9])\)? ?([2-9][0-9]{2})[-.●]?([0-9]{4})$/;
    if (!phoneRegex.test(value)) {
        return false;
    }
    return true;
}

export function normalizeInput(value) {
    if (!value) return value;
    
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (cvLength < 4) return currentValue;
    if (cvLength < 7) return `(${currentValue.slice(0, 3)})-${currentValue.slice(3)}`;
    
    return `(${currentValue.slice(0, 3)})-${currentValue.slice(3,6)}-${currentValue.slice(6, 10)}`;
}
