import { combineReducers } from "redux"
import auth from "./auth/auth"
import application from "./application/application"

const appReducer = combineReducers({
    auth,
    application
})

const initialState = appReducer({}, {})


const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT' || action.type === "LOGOUT_ALL_USER") {
        
        localStorage.clear();
        return appReducer(initialState, action)
    }
    return appReducer(state, action)
}


export default rootReducer