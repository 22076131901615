import React from "react";
import { SaleRepDropdown } from "../EditApplication/SaleRepDropdown";
import { Form, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateSalesRep } from "../../../redux/actions/application/application";
export const ApplicationSalesRep = (props) => {
    const formRef = React.createRef();
    const { subLoading } = useSelector(state => state.application)
    const dispatch = useDispatch()
    const { application, user } = props;
    const handleUpdateSaleRep = (formValues) => {
        formValues._id = application._id
        dispatch(updateSalesRep(formValues))
    };
    return (
        <Form
            ref={formRef}
            layout="vertical"
            onFinish={handleUpdateSaleRep}
            initialValues={{
                createdBy: application.createdBy,
                saleRepPhone: application.saleRepPhone,
                saleRepEmail: application.saleRepEmail,
            }}
        >
            <SaleRepDropdown formRef={formRef} user={user} />
            <Form.Item>
                <Button
                    size="large"
                    style={{borderRadius:"5px"}}
                    type="primary"
                    loading={subLoading}
                    htmlType="submit"
                >
                    Save
                </Button>
            </Form.Item>
        </Form>
    );
};
