export const GET_APPLICATION_LIST_STARTED = "GET_APPLICATION_LIST_STARTED"
export const GET_APPLICATION_LIST_SUCCESS = "GET_APPLICATION_LIST_SUCCESS"
export const GET_APPLICATION_LIST_FAILURE = "GET_APPLICATION_LIST_FAILURE"

export const CREATE_APPLICATION_STARTED = "CREATE_APPLICATION_STARTED"
export const CREATE_APPLICATION_SUCCESS = "CREATE_APPLICATION_SUCCESS"
export const CREATE_APPLICATION_FAILURE = "CREATE_APPLICATION_FAILURE"

export const GET_APPLICATION_STARTED = "GET_APPLICATION_STARTED"
export const GET_APPLICATION_SUCCESS = "GET_APPLICATION_SUCCESS"
export const GET_APPLICATION_FAILURE = "GET_APPLICATION_FAILURE"

export const SAVE_APPLICATION_STARTED = "SAVE_APPLICATION_STARTED"
export const SAVE_APPLICATION_SUCCESS = "SAVE_APPLICATION_SUCCESS"
export const SAVE_APPLICATION_FAILURE = "SAVE_APPLICATION_FAILURE"


export const UPDATE_STATUS_STARTED = "UPDATE_STATUS_STARTED"
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS"
export const UPDATE_STATUS_FAILURE = "UPDATE_STATUS_FAILURE"

export const ADD_COMMENT_STARTED = "ADD_COMMENT_STARTED"
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS"
export const ADD_COMMENT_FAILURE = "ADD_COMMENT_FAILURE"

export const DELETE_COMMENT_STARTED = "DELETE_COMMENT_STARTED"
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS"
export const DELETE_COMMENT_FAILURE = "DELETE_COMMENT_FAILURE"

export const DELETE_APPLICATION_STARTED = "DELETE_APPLICATION_STARTED"
export const DELETE_APPLICATION_SUCCESS = "DELETE_APPLICATION_SUCCESS"
export const DELETE_APPLICATION_FAILURE = "DELETE_APPLICATION_FAILURE"

export const UPLOAD_APPLICATION_FILE_STARTED = "UPLOAD_APPLICATION_FILE_STARTED"
export const UPLOAD_APPLICATION_FILE_SUCCESS = "UPLOAD_APPLICATION_FILE_SUCCESS"
export const UPLOAD_APPLICATION_FILE_FAILURE = "UPLOAD_APPLICATION_FILE_FAILURE"

export const REMOVE_APPLICATION_FILE_STARTED = "REMOVE_APPLICATION_FILE_STARTED"
export const REMOVE_APPLICATION_FILE_SUCCESS = "REMOVE_APPLICATION_FILE_SUCCESS"
export const REMOVE_APPLICATION_FILE_FAILURE = "REMOVE_APPLICATION_FILE_FAILURE"

export const GET_APPLICATION_FILE_URL_STARTED = "GET_APPLICATION_FILE_URL_STARTED"
export const GET_APPLICATION_FILE_URL_SUCCESS = "GET_APPLICATION_FILE_URL_SUCCESS"
export const GET_APPLICATION_FILE_URL_FAILURE = "GET_APPLICATION_FILE_URL_FAILURE"

export const SUBMIT_APPLICATION_TO_ADMIN_STARTED = "SUBMIT_APPLICATION_TO_ADMIN_STARTED"
export const SUBMIT_APPLICATION_TO_ADMIN_SUCCESS = "SUBMIT_APPLICATION_TO_ADMIN_SUCCESS"
export const SUBMIT_APPLICATION_TO_ADMIN_FAILURE = "SUBMIT_APPLICATION_TO_ADMIN_FAILURE"

export const UPDATE_SALES_REP_STARTED = "UPDATE_SALES_REP_STARTED"
export const UPDATE_SALES_REP_SUCCESS = "UPDATE_SALES_REP_SUCCESS"
export const UPDATE_SALES_REP_FAILURE = "UPDATE_SALES_REP_FAILURE"

export const SUBMIT_CANCELLATION_FORM_STARTED = "SUBMIT_CANCELLATION_FORM_STARTED"
export const SUBMIT_CANCELLATION_FORM_SUCCESS = "SUBMIT_CANCELLATION_FORM_SUCCESS"
export const SUBMIT_CANCELLATION_FORM_FAILURE = "SUBMIT_CANCELLATION_FORM_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"