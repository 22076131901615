import React, {useState} from "react";
import { Form, Row, Col, Input, Select, Timeline } from "antd";
import FormButtons from "./FormButtons";
import { useSelector, useDispatch } from "react-redux";
import { saveApplication } from "../../../redux/actions/application/application";

export default function NoteForm(props) {
    const formRef = React.createRef();
    const { application } = useSelector((state) => state.application);
    const dispatch = useDispatch();

    const [isFormUpdated, setIsFormUpdated] = useState(false)

    const noteFormData = {
        note: "",
        history: [],
    };

    for (const [key, value] of Object.entries(noteFormData)) {
        if (application.hasOwnProperty(key)) {
            noteFormData[key] = application[key];
        }
    }

    const saveForm = () => {
        if (isFormUpdated && application._id) {
            let formData = formRef.current.getFieldsValue();
            formData._id = application._id;
            setIsFormUpdated(false)
            dispatch(saveApplication(formData));
           
        }
    };

    return (
        <Form
            ref={formRef}
            layout='vertical'
            onFinish={saveForm}
            requiredMark='Required'
            initialValues={noteFormData}
            onFieldsChange={() => setIsFormUpdated(true)}
        >
            <FormButtons
                currentStep={props.currentStep}
                previousStep={props.previousStep}
                nextStep={props.nextStep}
                saveForm={saveForm}
                formRef={formRef}
            />
            <Row justify='space-between' style={{ width: "100%" }}>
                <Col span={24}>
                    {/* <Form.Item
                        label="Status"
                        name="status"
                        required
                        rules={[
                            {
                                required: true,
                                message: "Status is required.",
                            },
                        ]}
                    >
                        <Select>
                            <Option value="Draft">Draft</Option>
                            <Option value="Submitted to Admin">Submitted to Admin</Option>
                            <Option value="Submitted to Supplier">Submitted to Supplier</Option>
                            <Option value="Pending">Pending</Option>
                            <Option value="Approved">Approved</Option>
                            <Option value="Declined">Declined</Option>
                        </Select>
                    </Form.Item> */}
                    <Form.Item label='Note' name='note'>
                        <Input.TextArea rows={5}/>
                    </Form.Item>
                    {!application.status === "Draft" && (
                        <>
                            <h4>History</h4>
                            <Timeline mode='left'>
                                <Timeline.Item label='2022-02-10 09:12'>
                                    Timeline item 1
                                </Timeline.Item>
                                <Timeline.Item label='2022-02-10 12:34'>
                                    Timeline item 2
                                </Timeline.Item>
                                <Timeline.Item label='2022-02-13 10:52'>
                                    Timeline item 3
                                </Timeline.Item>
                                <Timeline.Item label='2022-02-15 15:05'>
                                    Timeline item 4
                                </Timeline.Item>
                            </Timeline>
                        </>
                    )}
                </Col>
            </Row>
        </Form>
    );
}
