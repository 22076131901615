import React from "react";
import { Collapse, Descriptions, Divider, Typography, Space } from "antd";

import moment from "moment";
const { Panel } = Collapse;
const { Paragraph, Text } = Typography;

export default function ApplicationOutline(props) {
    const { application } = props;

    const displayEquipment = (index, equip) => {
        const displayIndex = index + 1;
        if (equip) {
            return (
                <div key={index}>
                    <strong>Equipment {displayIndex}</strong>
                    <Descriptions
                        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                        key={"equipment" + displayIndex}
                        layout='vertical'
                        style={{ marginTop: "10px" }}
                    >
                        <Descriptions.Item label={<strong>Name</strong>}>
                            <Paragraph copyable={!!equip.name}>
                                {equip.name}
                            </Paragraph>
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={<strong>Purchse Type</strong>}
                        >
                            <Paragraph copyable={!!equip.purchaseType}>
                                {equip.purchaseType}
                            </Paragraph>
                        </Descriptions.Item>
                        <Descriptions.Item label={<strong>Price $</strong>}>
                            <Paragraph copyable={!!equip.unitPrice}>
                                {equip.unitPrice}
                            </Paragraph>
                        </Descriptions.Item>
                        <Descriptions.Item label={<strong>Quantity</strong>}>
                            <Paragraph copyable={!!equip.quantity}>
                                {equip.quantity}
                            </Paragraph>
                        </Descriptions.Item>
                        {equip.terminalId && (
                            <Descriptions.Item
                                label={<strong>Terminal Id</strong>}
                            >
                                <Paragraph copyable={!!equip.terminalId}>
                                    {equip.terminalId}
                                </Paragraph>
                            </Descriptions.Item>
                        )}
                        {equip.serialNumber && (
                            <Descriptions.Item
                                label={<strong>Serial Number</strong>}
                            >
                                <Paragraph copyable={!!equip.serialNumber}>
                                    {equip.serialNumber}
                                </Paragraph>
                            </Descriptions.Item>
                        )}
                        {equip.trackingNum && application.type === "First Data" && (
                            <Descriptions.Item
                                label={<strong>Tracking Number </strong>}
                            >
                                <a
                                    target='_blank'
                                    href={
                                        "https://www.fedex.com/fedextrack/?trknbr=" +
                                        equip.trackingNum
                                    }
                                >
                                    {equip.trackingNum}
                                </a>
                            </Descriptions.Item>
                        )}
                        {equip.trackingNum && application.type === "Elavon" && (
                            <Descriptions.Item
                                label={<strong>Tracking Number </strong>}
                            >
                                <a
                                    target='_blank'
                                    href={
                                        "https://www.purolator.com/en/shipping/tracker?pin=" +
                                        equip.trackingNum
                                    }
                                >
                                    {equip.trackingNum}
                                </a>
                            </Descriptions.Item>
                        )}
                        {equip.note && (
                            <Descriptions.Item label={<strong>Note</strong>}>
                                <Paragraph
                                    type='danger'
                                    copyable={!!equip.note}
                                >
                                    {equip.note}
                                </Paragraph>
                            </Descriptions.Item>
                        )}
                        {/* {equip.shippedDate && (
                            <Descriptions.Item
                                label={<strong>Shipped Date</strong>}
                            >
                                <Paragraph copyable={!!equip.shippedDate}>
                                    {equip.shippedDate}
                                </Paragraph>
                            </Descriptions.Item>
                        )} */}
                    </Descriptions>
                    <Divider />
                </div>
            );
        }
    };

    const displayCardPricing = (cardName, rate, transFee) => {
        return (
            <>
                <Descriptions.Item
                    label={<strong>{cardName + " rate"}</strong>}
                >
                    <Paragraph copyable>{rate}</Paragraph>
                </Descriptions.Item>
                <Descriptions.Item label={<strong>{cardName + " fee"}</strong>}>
                    <Paragraph copyable>
                        {" "}
                        {String(transFee).replace(/(.)(?=(\d{3})+$)/g, "$1,")}
                    </Paragraph>
                </Descriptions.Item>
            </>
        );
    };

    const displayAddress = (type, address) => {
        for (var key in address) {
            if (address[key] === "") {
                delete address[key];
            }
        }

        const addressArray = address ? Object.keys(address) : [];

        return (
            <Descriptions.Item label={<strong>{type}</strong>}>
                {addressArray.map((keyName, i) => {
                    return (
                        <div key={i}>
                            <Text copyable style={{ marginLeft: "4px" }}>
                                {address[keyName]}
                            </Text>
                            {i < addressArray.length - 1 ? ", " : ""}
                        </div>
                    );
                })}
            </Descriptions.Item>
        );
        // }
    };

    return (
        <Collapse
            defaultActiveKey={["0", "1"]}
            style={{ marginTop: "10px" }}
        // ghost
        >
            {application.note && (
                <Panel
                    key='0'
                    header={<span style={{ fontSize: 18 }}>Note</span>}
                >
                    <p
                        style={{
                            color: "red",
                            fontWeight: "bold",
                            fontSize: "1.2em",
                        }}
                    >
                        {application.note}
                    </p>
                </Panel>
            )}
            <Panel
                header={<span style={{ fontSize: 18 }}>Business Info</span>}
                key='1'
            >
                <Descriptions
                    layout='vertical'
                    key='businessInfo'
                    column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                >
                    {application.mid && (
                        <Descriptions.Item label={<strong>MID</strong>}>
                            <Paragraph copyable>{application.mid}</Paragraph>
                        </Descriptions.Item>
                    )}
                    <Descriptions.Item label={<strong>DBA Name</strong>}>
                        <Paragraph copyable>{application.dbaName}</Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={<strong>Google Link or Full Address</strong>}
                    >
                        {application.googleLink}
                    </Descriptions.Item>
                    <Descriptions.Item label={<strong>Business Phone</strong>}>
                        <Paragraph copyable={!!application.businessPhone}>
                            {application.businessPhone}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label={<strong>Email</strong>}>
                        <Paragraph copyable={!!application.email}>
                            {application.email}
                        </Paragraph>
                    </Descriptions.Item>

                    {displayAddress("DBA Address", application.dbaAddress)}
                    <Descriptions.Item label={<strong>Legal Name</strong>}>
                        <Paragraph copyable={!!application.legalName}>
                            {application.legalName}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label={<strong>GST Number</strong>}>
                        <Paragraph copyable={!!application.gstNum}>
                            {application.gstNum}
                        </Paragraph>
                    </Descriptions.Item>

                    {displayAddress("Legal Address", application.legalAddress)}
                    <Descriptions.Item label={<strong>Business Type</strong>}>
                        <Paragraph>{application.businessType}</Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label={<strong>Contact Name</strong>}>
                        <Paragraph copyable={!!application.contactName}>
                            {application.contactName}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={
                            <strong>
                                Year Established{" "}
                                {application.yearEstablished && (
                                    <span style={{ color: "gray" }}>
                                        (
                                        {moment(
                                            application.yearEstablished,
                                            "MMM YYYY"
                                        ).fromNow("MM YY")}
                                        )
                                    </span>
                                )}
                            </strong>
                        }
                    >
                        <Paragraph copyable={!!application.yearEstablished}>
                            {application.yearEstablished}
                        </Paragraph>
                    </Descriptions.Item>
                    {displayAddress(
                        "Mailing Address",
                        application.mailingAddress
                    )}
                    {displayAddress(
                        "Shipping Address",
                        application.shippingAddress
                    )}
                    <Descriptions.Item label={<strong>Website</strong>}>
                        <Paragraph copyable={!!application.website}>
                            {application.website}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={<strong>Number of Employees</strong>}
                    >
                        <Paragraph copyable={!!application.numberOfEmployee}>
                            {application.numberOfEmployee}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label={<strong>MCC Code</strong>}>
                        <Paragraph copyable={!!application.mccCode}>
                            {application.mccCode}
                        </Paragraph>
                    </Descriptions.Item>
                    {application.currentProcessor && (
                        <Descriptions.Item
                            label={<strong>Current Processor</strong>}
                        >
                            <Paragraph>
                                {application.currentProcessor}
                            </Paragraph>
                        </Descriptions.Item>
                    )}
                    <Descriptions.Item label={<strong>Annual Sales($)</strong>}>
                        <Paragraph copyable={!!application.annualSales}>
                            {String(application.annualSales).replace(
                                /(.)(?=(\d{3})+$)/g,
                                "$1,"
                            )}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={<strong>Annual Credit Card Vol($)</strong>}
                    >
                        <Paragraph
                            copyable={!!application.annualCreditCardVolume}
                        >
                            {String(application.annualCreditCardVolume).replace(
                                /(.)(?=(\d{3})+$)/g,
                                "$1,"
                            )}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={<strong>Annual Inte Vol($)</strong>}
                    >
                        <Paragraph copyable={!!application.annualInteracVolume}>
                            {String(application.annualInteracVolume).replace(
                                /(.)(?=(\d{3})+$)/g,
                                "$1,"
                            )}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={<strong>Ave Trans Size($)</strong>}
                    >
                        <Paragraph
                            copyable={!!application.estAverageTransactionSize}
                        >
                            {String(
                                application.estAverageTransactionSize
                            ).replace(/(.)(?=(\d{3})+$)/g, "$1,")}
                        </Paragraph>
                    </Descriptions.Item>
                </Descriptions>
            </Panel>

            {/* <Divider /> */}

            <Panel
                header={<span style={{ fontSize: 18 }}>Equipments</span>}
                key='2'
            >
                {application.equipments.map((equip, index) => {
                    return displayEquipment(index, equip);
                })}
            </Panel>

            <Panel
                header={<span style={{ fontSize: 18 }}>Funding</span>}
                key='3'
            >
                <Descriptions
                    layout='vertical'
                    key='funding'
                    column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                >
                    <Descriptions.Item label={<strong>Funding Run</strong>}>
                        <Paragraph copyable={!!application.fundingRun}>
                            {application.fundingRun}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label={<strong>Auto Close Time</strong>}>
                        <Paragraph copyable={!!application.autoCloseTime}>
                            {application.autoCloseTime}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label={<strong>Auto Settle</strong>}>
                        <Paragraph>
                            {application.autoSettle ? "Yes" : "No"}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={<strong>Comment to Credit Officer</strong>}
                    >
                        <Paragraph
                            copyable={!!application.commentToCreditOfficer}
                        >
                            {application.commentToCreditOfficer}
                        </Paragraph>
                    </Descriptions.Item>
                </Descriptions>
            </Panel>

            {/* <Divider /> */}

            <Panel
                header={<span style={{ fontSize: 18 }}>Pricing</span>}
                key='4'
            >
                <Descriptions
                    layout='vertical'
                    key='pricing'
                    column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                >
                    <Descriptions.Item label={<strong>Card Types</strong>}>
                        <Paragraph>
                            {application.cardTypes.join(", ")}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label={<strong>Credit Rate</strong>}>
                        <Paragraph copyable={!!application.creditRate}>
                            {" "}
                            {String(application.creditRate).replace(
                                /(.)(?=(\d{3})+$)/g,
                                "$1,"
                            )}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={<strong>Credit Trans Fee</strong>}
                    >
                        <Paragraph copyable={!!application.creditTransFee}>
                            {" "}
                            {String(application.creditTransFee).replace(
                                /(.)(?=(\d{3})+$)/g,
                                "$1,"
                            )}
                        </Paragraph>
                    </Descriptions.Item>
                    {(application.cardTypes.includes("Visa") ||
                        application.cardTypes.includes("Visa Debit")) &&
                        displayCardPricing(
                            "Visa",
                            application.visaRate,
                            application.visaTransFee
                        )}
                    {application.cardTypes.includes("Master Card") &&
                        displayCardPricing(
                            "Master Card",
                            application.masterCardRate,
                            application.masterCardTransFee
                        )}
                    {application.cardTypes.includes("Amex/JCB") &&
                        displayCardPricing(
                            "Amex/JCB",
                            application.amexJcbRate,
                            application.amexJcbTransFee
                        )}
                    {application.cardTypes.includes("Discover") &&
                        displayCardPricing(
                            "Discover",
                            application.discoverRate,
                            application.discoverTransFee
                        )}
                    <Descriptions.Item
                        label={<strong>Interact App Trans Fee</strong>}
                    >
                        <Paragraph copyable>
                            {application.interacApprovedTransFee}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={<strong>Interact Decl Trans fee</strong>}
                    >
                        <Paragraph copyable>
                            {application.interacDeclinedTransFee}
                        </Paragraph>
                    </Descriptions.Item>
                </Descriptions>
            </Panel>

            {/* <Divider /> */}

            <Panel
                header={<span style={{ fontSize: 18 }}>Other Fees</span>}
                key='5'
            >
                <Descriptions
                    layout='vertical'
                    key='otherFees'
                    column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                >
                    <Descriptions.Item label={<strong>Annual Fee</strong>}>
                        <Paragraph copyable>{application.annualFee}</Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label={<strong>Application Fee</strong>}>
                        <Paragraph copyable>
                            {application.applicationFee}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={<strong>Charge Back Billing</strong>}
                    >
                        <Paragraph copyable>
                            {application.chargeBackBilling}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={<strong>Min Discount Fee</strong>}
                    >
                        <Paragraph copyable>
                            {application.minDiscountFee}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label={<strong>Monthly Fee</strong>}>
                        <Paragraph copyable>{application.monthlyFee}</Paragraph>
                    </Descriptions.Item>
                    {application.annualComplianceFee ? (
                        <Descriptions.Item
                            label={<strong>Annual Compliance Fee</strong>}
                        >
                            <Paragraph copyable>
                                {application.annualComplianceFee}
                            </Paragraph>
                        </Descriptions.Item>
                    ) : (
                        <Descriptions.Item
                            label={<strong>Quaterly Compliance Fee</strong>}
                        >
                            <Paragraph copyable>
                                {application.quarterlyComplianceFee}
                            </Paragraph>
                        </Descriptions.Item>
                    )}
                    <Descriptions.Item label={<strong>Statement Fee</strong>}>
                        <Paragraph copyable>
                            {application.statementFee}
                        </Paragraph>
                    </Descriptions.Item>
                </Descriptions>
            </Panel>

            {/* <Divider /> */}

            <Panel header={<span style={{ fontSize: 18 }}>Owner</span>} key='6'>
                <Descriptions
                    layout='vertical'
                    key='owner'
                    column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                >
                    <Descriptions.Item label={<strong>Full Name</strong>}>
                        <Paragraph copyable={!!application.fullName}>
                            {application.fullName}
                        </Paragraph>
                    </Descriptions.Item>

                    <Descriptions.Item label={<strong>Title</strong>}>
                        <Paragraph copyable={!!application.title}>
                            {application.title}
                        </Paragraph>
                    </Descriptions.Item>

                    <Descriptions.Item label={<strong>Phone</strong>}>
                        <Paragraph copyable={!!application.ownerPhone}>
                            {application.ownerPhone}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label={<strong>Email</strong>}>
                        <Paragraph copyable={!!application.ownerEmail}>
                            {application.ownerEmail}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={<strong>Percentage Owned %</strong>}
                    >
                        <Paragraph copyable={!!application.percentageOwned}>
                            {application.percentageOwned}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label={<strong>SIN Number</strong>}>
                        <Paragraph copyable={!!application.sinNum}>
                            {application.sinNum}
                        </Paragraph>
                    </Descriptions.Item>
                    {displayAddress("Owner Address", application.ownerAddress)}
                    <Descriptions.Item label={<strong>DOB</strong>}>
                        <Paragraph>
                            {application.dobDay} {application.dobMonth}{" "}
                            {application.dobYear}
                        </Paragraph>
                    </Descriptions.Item>
                    {/* <Descriptions.Item label={<strong>DOB Month</strong>}>
                        <Paragraph copyable={!!application.dobMonth}>
                            {application.dobMonth}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label={<strong>DOB Year</strong>}>
                        <Paragraph copyable={!!application.dobYear}>
                            {application.dobYear}
                        </Paragraph>
                    </Descriptions.Item> */}
                </Descriptions>
            </Panel>
        </Collapse>
    );
}
