import API from "../../../utils/API/API";

import {
    LOGOUT,
    LOGIN_STARTED,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    UPDATE_PASSWORD_STARTED,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAILURE,
} from "../../constants/auth/auth"


export const updatePassword = (passwords) => {
    return dispatch => {
        dispatch(updatePasswordStarted())
        API.patch(`/api/employee-accounts/update-password`, passwords).then(() => {
            dispatch(updatePasswordSuccess())
            dispatch(logout())
        }).catch(err => {
            dispatch(updatePasswordFailure(err.message))
        })
    }
}

const updatePasswordStarted = () => ({
    type: UPDATE_PASSWORD_STARTED
})

const updatePasswordSuccess = () => ({
    type: UPDATE_PASSWORD_SUCCESS,
})

const updatePasswordFailure = error => ({
    type: UPDATE_PASSWORD_FAILURE,
    payload: {error}
})

export const auth = (user) => {
    return dispatch => {
        dispatch(loginStarted())
        const email = user.email
        const password = user.password
        API.post(`/api/employee-accounts/login`, {
            email,
            password
        }).then(res => {
            dispatch(loginSuccess(res.data))
        }).catch(err => {
            dispatch(loginFailure(err.message))
        })
    }
}


const loginStarted = () => ({
    type: LOGIN_STARTED
})

const loginSuccess = data => ({
    type: LOGIN_SUCCESS,
    payload: {
        data
    }
})

const loginFailure = error => ({
    type: LOGIN_FAILURE,
    payload: {error}
})


export function logout() {
    API.delete(`/api/employee-accounts/logout`).then(res => {
        console.log("log out")
    })
    return { type: LOGOUT, payload: null };
  }
