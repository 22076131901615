import { useState, useEffect } from "react";
import { Steps, Divider, Space } from "antd";
import styles from "./EditApplicationPage.module.css";
import BusinessInformationForm from "../../../components/Application/EditApplication/BusinessInformationForm";
import EquipmentForm from "../../../components/Application/EditApplication/EquipmentForm";
import PricingForm from "../../../components/Application/EditApplication/PricingForm";
import OwnerForm from "../../../components/Application/EditApplication/OwnerForm";
import FundingForm from "../../../components/Application/EditApplication/FundingForm";
import NoteForm from "../../../components/Application/EditApplication/NoteForm";
import SubmissionForm from "../../../components/Application/EditApplication/SubmissionForm";
import { useDispatch, useSelector } from "react-redux";
import { getApplication } from "../../../redux/actions/application/application";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Loading } from "../../../components/common/Loading";
import { ErrorMessage } from "../../../components/common/ErrorMessage";
import { ApplicationStatus } from "../../../components/Application/ApplicationStatus";

const { Step } = Steps;

function EditApplicationPage(props) {
    const [currentStep, setCurrentStep] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    const dispatch = useDispatch();
    const { application, loading, error, message } = useSelector(
        (state) => state.application
    );
    const { user } = useSelector((state) => state.auth);
    const [searchParams] = useSearchParams();
    let navigate = useNavigate();

    const fetchApplicationData = () => {
        const applicationId = searchParams.get("id");
        dispatch(getApplication(applicationId));

    };

    function handleWindowSizeChange() {
        let isMobile = false;

        if (window.innerWidth <= 768) {
            isMobile = true;
        }

        setIsMobile(isMobile);
    }

    useEffect(() => {
        fetchApplicationData();

        // Event listener for isMobile
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        if (error || message) {
            return ErrorMessage(error, message);
        }
        if (application && (application.status !== "Draft" && !["admin", "developer"].includes(user.role))) {
            // ErrorMessage("Access Denied!")
            return navigate("/");
        }
    }, [application, error, message]);

    const previousStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const nextStep = () => {
        if (currentStep < 6) {
            setCurrentStep(currentStep + 1);
        }
    };

    if (loading || !application) {
        return <Loading />;
    }

    const onChange = (current) => {
        setCurrentStep(current)
    }

    return (
        <div className={styles.container}>
            <div>
                {application && (
                    <Space>
                        <span className={styles["application-name"]}>
                            {application.dbaName}
                        </span>
                        <span className={styles["application-status"]}>
                            <ApplicationStatus status={application.status} />
                        </span>

                    </Space>
                )}
                <span style={{ float: "right" }}>
                    {application.type === "First Data" && (
                        <img
                            src='/assets/logos/firstdata.svg'
                            style={{ margin: "0px", float: "right" }}
                            alt='First Data'
                            width={100}
                        />
                    )}
                    {application.type === "Elavon" && (
                        <img
                            src='/assets/logos/elavon.svg'
                            width={80}
                            style={{ margin: "0px", float: "right" }}
                            alt='Elavon'
                        />
                    )}
                </span>
            </div>
            <Steps
                size='small'
                progressDot
                current={currentStep}
                responsive={false}
                type={isMobile ? "default" : "navigation"}
                direction={isMobile ? "vertical" : "horizontal"}
                className={styles.steps}
                onChange={user.role === "admin" && onChange}
            >
                <Step
                    title='Business Info'
                    className={!isMobile && styles["step-item"]}
                />
                <Step
                    title='Equipment'
                    className={!isMobile && styles["step-item"]}
                />
                <Step
                    title='Pricing'
                    className={!isMobile && styles["step-item"]}
                />
                <Step
                    title='Owner'
                    className={!isMobile && styles["step-item"]}
                />
                <Step
                    title='Funding'
                    className={!isMobile && styles["step-item"]}
                />
                <Step
                    title='Note'
                    className={!isMobile && styles["step-item"]}
                />
                <Step
                    title='Submission'
                    className={!isMobile && styles["step-item"]}
                />
            </Steps>
            <Divider />
            {loading ? (
                <Loading />
            ) : (
                <div style={{ position: "relative" }}>
                    {currentStep === 0 && (
                        <BusinessInformationForm
                            currentStep={currentStep}
                            previousStep={previousStep}
                            nextStep={nextStep}
                        />
                    )}
                    {currentStep === 1 && (
                        <EquipmentForm
                            currentStep={currentStep}
                            previousStep={previousStep}
                            nextStep={nextStep}
                        />
                    )}
                    {currentStep === 2 && (
                        <PricingForm
                            currentStep={currentStep}
                            previousStep={previousStep}
                            nextStep={nextStep}
                        />
                    )}
                    {currentStep === 3 && (
                        <OwnerForm
                            currentStep={currentStep}
                            previousStep={previousStep}
                            nextStep={nextStep}
                        />
                    )}
                    {currentStep === 4 && (
                        <FundingForm
                            currentStep={currentStep}
                            previousStep={previousStep}
                            nextStep={nextStep}
                        />
                    )}
                    {currentStep === 5 && (
                        <NoteForm
                            currentStep={currentStep}
                            previousStep={previousStep}
                            nextStep={nextStep}
                        />
                    )}
                    {currentStep === 6 && (
                        <SubmissionForm
                            currentStep={currentStep}
                            previousStep={previousStep}
                            nextStep={nextStep}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

export default EditApplicationPage;
