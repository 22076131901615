import React from "react";
import { Form, Input, Button, DatePicker } from "antd";
import moment from "moment"
const { TextArea } = Input;

export const ElavonCancellationForm = (props) => {
    const { application, formRef, subLoading } = props;
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
      }
    return (
        <Form
            ref={formRef}
            layout="vertical"
            onFinish={props.handleSubmitCancelForm}
            initialValues={{
                mid: application.mid,
                dba: application.dbaName,
                saleRepEmail: application.saleRepEmail,
                mspOfficeName: "Dash Booking Inc",
                customerName: application.fullName,
                mspShortName: "ICC369A1",
                mspSubmitterEmailAddress: "merchants@dashbooking.com",
                mspSubmitterName: "Duc Hoang Dang",
                customerEmailAddress: application.email,
                effectiveClosureDate: moment(),
                shippingAddress:
                    Object.values(application.dbaAddress).join(", ") || "",
            }}
        >
            <Form.Item
                label="MID"
                name="mid"
                rules={[
                    {
                        required: true,
                        message: "MID is required",
                    },
                   
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="DBA"
                name="dba"
                rules={[
                    {
                        required: true,
                        message: "DBA name is required",
                    },
                   
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Customer Name (First and Last)"
                name="customerName"
                rules={[
                    {
                        required: true,
                        message: "Customer name is required",
                    },
                   
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Customer Email Address"
                name="customerEmailAddress"
                rules={[
                    {
                        required: true,
                        message: "Customer email is required",
                    },
                    {
                        type: "email",
                        message: "Please enter a valid email address.",
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="MSP Office Name"
                name="mspOfficeName"
                hidden
            >
                <Input disabled/>
            </Form.Item>
            <Form.Item
                label="MSP Short Name"
                name="mspShortName"
                hidden
            >
                <Input disabled/>
            </Form.Item>
            <Form.Item
                label="MSP Submitter Name (First and Last)"
                name="mspSubmitterName"
                hidden
            >
                <Input disabled/>
            </Form.Item>
            <Form.Item
                label="MSP Submitter Email Address"
                name="mspSubmitterEmailAddress"
                hidden
              
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Effective Closure Date"
                name="effectiveClosureDate"
                rules={[
                    {
                        required: true,
                        message: "Date is required",
                    },
                   
                ]}
            >
                <DatePicker  disabledDate={disabledDate}/>
            </Form.Item>
            <Form.Item
                label="Closure Reason"
                name="closureReason"
                rules={[
                    {
                        required: true,
                        message: "Closure reason is required",
                    },
                   
                ]}
            >
                <TextArea />
            </Form.Item>
            <Form.Item label="Shipping Address" name="shippingAddress">
                <Input />
            </Form.Item>

            <Form.Item>
                <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    style={{ borderRadius:"5px", width: "100%" }}
                    loading={subLoading}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};
