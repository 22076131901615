import React, { useEffect } from "react";
import styles from "./NewApplicationPage.module.css";
import {
    formatPhoneNumber,
    validatePhoneNumber,
} from "../../../utils/FormatPhoneNumber/FormatPhoneNumber";
import { Form, Row, Col, Input, Select, Button, Modal } from "antd";
import { CloseOutlined, DeleteTwoTone } from "@ant-design/icons";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createApplication } from "../../../redux/actions/application/application";
import { ErrorMessage } from "../../../components/common/ErrorMessage";
import { SaleRepDropdown } from "../../../components/Application/EditApplication/SaleRepDropdown";
const { Option } = Select;

function NewApplicationPage(props) {
    const formRef = React.createRef();
    const { user } = useSelector((state) => state.auth);
    const { loading, application, error, message } = useSelector(
        (state) => state.application
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (error || message) {
            return ErrorMessage(error, message);
        }
        if (application) {
            navigate({
                pathname: "/application/edit",
                search: `?${createSearchParams({
                    id: application._id,
                })}`,
            });
        }
    }, [application, error, message, navigate]);
    const handleCreateApplication = (formValues) => {
        dispatch(createApplication(formValues));
    };

    const confirmCloseApplication = () => {
        Modal.confirm({
            title: "Close application?",
            icon: <DeleteTwoTone twoToneColor={"#ff4d4f"} />,
            content: "All progress will be deleted.",
            okType: "danger",
            okText: "Delete",
            onOk() {
                navigate("/");
            },
        });
    };

    return (
        <div className={styles.container}>
            <h2>
                New Application{" "}
                <Button
                    type='link'
                    danger
                    style={{ float: "right" }}
                    icon={<CloseOutlined />}
                    shape='circle'
                    onClick={confirmCloseApplication}
                />
            </h2>

            <Form
                ref={formRef}
                layout='vertical'
                onFinish={handleCreateApplication}
                requiredMark='required'
                initialValues={{
                    currency: "CAD",
                    createdBy: user.name,
                    saleRepPhone: user.phone,
                    saleRepEmail: user.email,
                    languagePreference: "English",
                    selectPartner: "Dash Booking Inc",
                    currentProcessor: "",
                }}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label='Type'
                            name='type'
                            required
                            className={styles.formItem}
                            rules={[
                                {
                                    required: true,
                                    message: "Application Type is required",
                                },
                            ]}
                        >
                            <Select allowClear optionLabelProp='label'>
                                <Option value='First Data' label='First Data'>
                                    <img
                                        src='/assets/logos/firstdata.svg'
                                        height={50}
                                        alt='First Data'
                                    />
                                </Option>
                                <Option value='Elavon' label='Elavon'>
                                    <img
                                        src='/assets/logos/elavon.svg'
                                        height={50}
                                        alt='Elavon'
                                    />
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    justify='space-between'
                    align='stretch'
                    style={{ width: "100%" }}
                >
                    <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                        <Form.Item
                            label='DBA Name'
                            name='dbaName'
                            required
                            className={styles.formItem}
                            rules={[
                                {
                                    required: true,
                                    message: "DBA name is required.",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label='Contact Name'
                            name='contactName'
                            required
                            className={styles.formItem}
                            rules={[
                                {
                                    required: true,
                                    message: "Contact name is required.",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label='Business Phone Number'
                            name='businessPhone'
                            required
                            className={styles.formItem}
                            normalize={(value) => formatPhoneNumber(value)}
                            rules={[
                                {
                                    required: true,
                                    message: "Phone number is required.",
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (
                                            !value ||
                                            validatePhoneNumber(value)
                                        ) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            "Please enter a valid phone number!"
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input placeholder='(xxx) xxx-xxxx' />
                        </Form.Item>
                        <Form.Item
                            label='Email Address'
                            name='email'
                            required
                            className={styles.formItem}
                            rules={[
                                {
                                    required: true,
                                    message: "Email address is required.",
                                },
                                {
                                    type: "email",
                                    message: "Enter a valid email address.",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label='Current Processor'
                            name='currentProcessor'
                            required
                            className={styles.formItem}
                            rules={[
                                {
                                    required: true,
                                    message: "Current Processor is required.",
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        const type = getFieldValue("type");
                                        if (
                                            type === "Elavon" &&
                                            value ===
                                                "Elavon Under Diffirent Partner"
                                        ) {
                                            return Promise.reject(
                                                "Elavon does not tolerate resigns. This application cannot be created"
                                            );
                                        }
                                        if (
                                            type === "Elavon" &&
                                            value === "Unknown"
                                        ) {
                                            return Promise.reject(
                                                "Elavon does not tolerate resigns. Please make sure the processor of the current business is not Elavon before submiting this application."
                                            );
                                        }
                                        if (
                                            type === "First Data" &&
                                            value === "Unknown"
                                        ) {
                                            return Promise.reject(
                                                "FD does not tolerate resigns. Please make sure the processor of the current business is not First Data before submiting this application."
                                            );
                                        }
                                        if (
                                            type === "First Data" &&
                                            value ===
                                                "First Data Under Diffirent Partner"
                                        ) {
                                            return Promise.reject(
                                                "FD does not tolerate resigns. This application cannot be created."
                                            );
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Select>
                                <Option value=''></Option>
                                <Option value='Elavon Under Diffirent Partner'>
                                    Elavon Under Diffirent Partner
                                </Option>
                                <Option value='Elavon Under Dash Booking'>
                                    Elavon Under Dash Booking
                                </Option>
                                <Option value='First Data Under Diffirent Partner'>
                                    First Data Under Diffirent Partner
                                </Option>
                                <Option value='First Data Under Dash Booking'>
                                    First Data Under Dash Booking
                                </Option>
                                <Option value='Other'>Other</Option>
                                <Option value='Unknown'>Unknown</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                        <Form.Item
                            label='Currency'
                            name='currency'
                            required
                            className={styles.formItem}
                            rules={[
                                {
                                    required: true,
                                    message: "Currency is required.",
                                },
                            ]}
                        >
                            <Select>
                                <Option value='CAD'>CAD</Option>
                                <Option value='USD'>USD</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label='Language Preference'
                            name='languagePreference'
                            required
                            className={styles.formItem}
                            rules={[
                                {
                                    required: true,
                                    message: "Language Preference is required.",
                                },
                            ]}
                        >
                            <Select>
                                <Option value='English'>English</Option>
                                <Option value='French'>French</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label='Select Partner'
                            name='selectPartner'
                            className={styles.formItem}
                            required
                        >
                            <Select>
                                <Select.Option value='Dash Booking Inc'>
                                    Dash Booking Inc
                                </Select.Option>
                                <Select.Option value='SHELFER, VU & ASSOCIATES / 30971'>
                                    SHELFER, VU & ASSOCIATES / 30971
                                </Select.Option>
                            </Select>
                        </Form.Item>

                        {user.role === "admin" && (
                            <SaleRepDropdown formRef={formRef} user={user} />
                        )}
                    </Col>
                </Row>

                <Form.Item>
                    <Button
                        size='large'
                        loading={loading}
                        type='primary'
                        htmlType='submit'
                    >
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default NewApplicationPage;
