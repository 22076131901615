import { useEffect, useState } from "react";
import styles from "./Dashboard.module.css";
import { Row, Col, Button, DatePicker } from "antd";
import { FilterOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationList } from "../../redux/actions/application/application";
import DashboardTable from "../../components/Dashboard/DashboardTable";
import { ErrorMessage } from "../../components/common/ErrorMessage";
import DashboardFilter from "../../components/Dashboard/DashboardFilter";
import { useSearchParams } from "react-router-dom";
import queryString from 'query-string';
import moment from "moment";

const allFilterFields = [
    "month",
    "mid",
    "dbaName",
    "type",
    "province",
    "createdBy",
    "status",
    "fullName",
    "ownerPhone",
    "startDate",
    "endDate",
    "createdAt",
    "purchaseType"

];

function Dashboard() {
    const dispatch = useDispatch();
    const {
        applicationList,
        count,
        loading,
        error,
        message,
        applicationDeleted,
        applicationSubmitted,
    } = useSelector((state) => state.application);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isMobile, setIsMobile] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    let [selectMonth, setSelectMonth] = useState(moment());
    let [filterObject, setFilterObject] = useState({})
    const handleWindowSizeChange = () => {
        let isMobile = false;

        if (window.innerWidth <= 768) {
            isMobile = true;
        }

        setIsMobile(isMobile);
    };

    useEffect(() => {
        const querySearch = queryString.parse(location.search)
        if (querySearch?.month) {
            setSelectMonth(moment(querySearch.month, 'YYYY-MM'))
        }
        fetchApplicationList()
        // Event listener for isMobile
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };

    }, [currentPage]);


    const fetchApplicationList = () => {
        let urlParams = {};
        let selectedMonthFromUrl = selectMonth
        const querySearch = queryString.parse(location.search)

        if (querySearch?.month) {
            selectedMonthFromUrl = moment(querySearch.month, 'YYYY-MM')
        }
        if (querySearch?.page) {
            urlParams.page = querySearch.page
        }


        for (const entry of searchParams.entries()) {
            const [param, value] = entry;
            urlParams[param] = value;
        }

        const startDate = selectedMonthFromUrl
            .startOf("month")
            .toDate();
        const endDate = selectedMonthFromUrl.endOf("month").toDate();
        urlParams.createdAt = { $lte: endDate, $gte: startDate };
        urlParams.page = currentPage

        dispatch(getApplicationList(urlParams));
    }

    useEffect(() => {
        if (applicationDeleted) {
            return ErrorMessage(
                null,
                "Application has been removed successfully!"
            );
        }
        if (applicationSubmitted) {
            return ErrorMessage(
                null,
                "Application has been submitted successfully!"
            );
        }
        if (error || message) {
            return ErrorMessage(error, message);
        }
    }, [error, message, applicationDeleted, applicationSubmitted]);

    const handleSubmitFilter = (filterObject) => {
        let query = {};

        if (filterObject.createdAt) {
            const startTime = filterObject.createdAt[0].startOf('date')
            const endTime = filterObject.createdAt[1].endOf('date')
            const selectedMonthFromUrl = filterObject.createdAt[0].format("YYYY-MM")
            const createdAt = { $gte: startTime.toDate(), $lte: endTime.toDate() }
            filterObject.createdAt = createdAt
        }
        allFilterFields.forEach((field) => {
            if (filterObject.hasOwnProperty(field) && filterObject[field]) {
                query[field] = filterObject[field];
            }
        });




        setFilterObject(query)

        let querySearch = { ...query }
        delete querySearch['createdAt']

        setSearchParams(querySearch);
        setIsDrawerVisible(false);
        // const startDate = selectMonth
        //     .startOf("month").toDate();
        // const endDate = selectMonth.endOf("month").toDate();
        // query.createdAt = { $lte: endDate, $gte: startDate };
        query.page = currentPage
        dispatch(getApplicationList(query));
    };

    const goToPage = (page) => {
        setCurrentPage(page)
    }

    const onChangeDate = (value) => {
        setSelectMonth(value);
        const startDate = value.startOf("month").toDate();
        const endDate = value.endOf("month").toDate();
        const selectedMonthFormat = value.format("YYYY-MM")
        let urlParams = {};
        for (const entry of searchParams.entries()) {
            const [param, value] = entry;
            urlParams[param] = value;
        }
        urlParams.createdAt = { $lte: endDate, $gte: startDate };
        urlParams.page = currentPage
        const stringified = queryString.stringify({ month: selectedMonthFormat, page: currentPage })
        window.history.pushState(null, '', `${window.location.pathname}?${stringified}`);
        dispatch(getApplicationList(urlParams));
    };

    return (
        <div className={styles.container}>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
                <Col>
                    <h1>Dashboard</h1>
                </Col>
                <Col>
                    <DatePicker
                        onChange={onChangeDate}
                        picker="month"
                        format="MMM YYYY"
                        value={selectMonth}
                        allowClear={false}
                    />
                    <Button
                        icon={<FilterOutlined />}
                        onClick={() => setIsDrawerVisible(true)}
                        style={{ borderRadius: 5, marginRight: 5, color: Object.entries(filterObject).length > 1 ? "#f5222d" : "#262626" }}
                    >
                        Filter
                    </Button>
                    <Button
                        href="/application/new"
                        style={{ borderRadius: 5 }}
                        type="primary"
                    >
                        <PlusOutlined /> New Application
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DashboardTable
                        loading={loading}
                        applicationList={applicationList}
                        currentPage={currentPage}
                        count={count}
                        goToPage={goToPage}
                    />
                </Col>
            </Row>
            <DashboardFilter
                isMobile={isMobile}
                isDrawerVisible={isDrawerVisible}
                setIsDrawerVisible={setIsDrawerVisible}
                handleSubmitFilter={handleSubmitFilter}
            />
        </div>
    );
}

export default Dashboard;
