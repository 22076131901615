import React from "react";
import { Timeline, Tooltip } from "antd";
import moment from "moment";
export const ApplicationHistory = (props) => {
    let { history } = props;
    history = history.sort((a, b) =>   {  return new Date(b.date) - new Date(a.date)})
    return (
        <div>
            <h2>History</h2>
            <Timeline>
                {history.map((event, index) => {
                    return (
                        <Timeline.Item key={index}>
                            <p style={{ color: "#8c8c8c" }}>
                                {" "}
                                <Tooltip
                                    title={moment(event.date).format(
                                        "MMM DD, yyyy hh:mma"
                                    )}
                                >
                                    <span>{moment(event.date).fromNow()}</span>{" "}
                                    by {event.by}{" "}
                                </Tooltip>
                            </p>
                            <p>{event.story}</p>
                        </Timeline.Item>
                    );
                })}
            </Timeline>
        </div>
    );
};
