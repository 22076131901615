import {
    GET_APPLICATION_LIST_STARTED,
    GET_APPLICATION_LIST_SUCCESS,
    GET_APPLICATION_LIST_FAILURE,
    CREATE_APPLICATION_STARTED,
    CREATE_APPLICATION_SUCCESS,
    CREATE_APPLICATION_FAILURE,
    GET_APPLICATION_STARTED,
    GET_APPLICATION_SUCCESS,
    GET_APPLICATION_FAILURE,
    SAVE_APPLICATION_STARTED,
    SAVE_APPLICATION_SUCCESS,
    SAVE_APPLICATION_FAILURE,
    ADD_COMMENT_STARTED,
    ADD_COMMENT_SUCCESS,
    ADD_COMMENT_FAILURE,
    UPDATE_STATUS_STARTED,
    UPDATE_STATUS_SUCCESS,
    UPDATE_STATUS_FAILURE,
    DELETE_COMMENT_STARTED,
    DELETE_COMMENT_SUCCESS,
    DELETE_COMMENT_FAILURE,
    DELETE_APPLICATION_STARTED,
    DELETE_APPLICATION_SUCCESS,
    DELETE_APPLICATION_FAILURE,
    UPLOAD_APPLICATION_FILE_STARTED,
    UPLOAD_APPLICATION_FILE_SUCCESS,
    UPLOAD_APPLICATION_FILE_FAILURE,
    REMOVE_APPLICATION_FILE_STARTED,
    REMOVE_APPLICATION_FILE_SUCCESS,
    REMOVE_APPLICATION_FILE_FAILURE,
    GET_APPLICATION_FILE_URL_STARTED,
    GET_APPLICATION_FILE_URL_SUCCESS,
    GET_APPLICATION_FILE_URL_FAILURE,
    SUBMIT_APPLICATION_TO_ADMIN_STARTED,
    SUBMIT_APPLICATION_TO_ADMIN_SUCCESS,
    SUBMIT_APPLICATION_TO_ADMIN_FAILURE,
    UPDATE_SALES_REP_STARTED,
    UPDATE_SALES_REP_SUCCESS,
    UPDATE_SALES_REP_FAILURE,
    SUBMIT_CANCELLATION_FORM_STARTED,
    SUBMIT_CANCELLATION_FORM_SUCCESS,
    SUBMIT_CANCELLATION_FORM_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/application/application";

const initialState = {
    applicationList: [],
    count: 0,
    application: null,
    applicationDeleted: false,
    applicationSubmitted: false,
    applicationDocumentUrls: {},
    loading: false,
    subLoading: false,
    error: null,
    message: null,
};

export default function application(state = initialState, action) {
    switch (action.type) {
        case UPDATE_SALES_REP_STARTED:
            return {
                ...state,
                subLoading: true,
            }
        case UPDATE_SALES_REP_SUCCESS:
            return {
                ...state,
                subLoading: false,
                application: {...state.application, ...action.payload},
                message:"Application has been saved!"
            }
        case UPDATE_SALES_REP_FAILURE:
            return {
                ...state,
                subLoading: false,
                error: action.payload.error
            }
        case UPDATE_STATUS_STARTED:
            return {
                ...state,
                subLoading: true,
            }
        case UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                subLoading: false,
                message: "Application Status has been updated!",
                application: {...state.application, status: action.payload.status, history: action.payload.history}
            }
        case UPDATE_STATUS_FAILURE:
            return {
                ...state,
                subLoading: false,
                error: action.payload.error
            }
        case ADD_COMMENT_STARTED:
            return {
                ...state,
                subLoading: true,
            }
        case ADD_COMMENT_SUCCESS:
            return {
                ...state,
                subLoading: false,
                message:"Comment added.",
                application: {...state.application, comments: [...state.application.comments, action.payload.newComment]}
            }
        case ADD_COMMENT_FAILURE:
            return {
                subLoading: false,
                error: action.payload.error
            }
        case DELETE_COMMENT_STARTED:
            return {
                ...state,
                subLoading: true,
            }
        case DELETE_COMMENT_SUCCESS:

            return {
                ...state,
                subLoading: false,
                message:"Comment deleted.",
                application: {...state.application, comments: state.application.comments.filter(comment => comment._id !==action.payload)}
            }
        case DELETE_COMMENT_FAILURE:
            return {
                subLoading: false,
                error: action.payload.error
            }
        case DELETE_APPLICATION_STARTED:
            return {
                ...state,
                loading: true
            }
        case DELETE_APPLICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                applicationList: state.applicationList.filter(app => app._id !== action.payload),
                applicationDeleted: true,
            }
        case DELETE_APPLICATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        case SUBMIT_APPLICATION_TO_ADMIN_STARTED:
            return {
                ...state,
                loading: true,
            };
        case SUBMIT_APPLICATION_TO_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                application: {
                    ...state.application,
                    status: "Submitted to Admin",
                },
                applicationSubmitted: true
            };
        case SUBMIT_APPLICATION_TO_ADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case GET_APPLICATION_LIST_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_APPLICATION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                applicationList: action.payload.applicationList,
                count: action.payload.count
            };
        case GET_APPLICATION_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case SAVE_APPLICATION_STARTED:
            return {
                ...state,
                loading: true,
            };
        case SAVE_APPLICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                application: action.payload.application,
                message: "Application saved successfully.",
            };
        case SAVE_APPLICATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case GET_APPLICATION_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_APPLICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                application: action.payload.application,
                applicationDocumentUrls: {},
            };
        case GET_APPLICATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case CREATE_APPLICATION_STARTED:
            return {
                ...state,
                loading: true,
            };
        case CREATE_APPLICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                application: action.payload.application,
            };
        case CREATE_APPLICATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case UPLOAD_APPLICATION_FILE_STARTED:
            return {
                ...state,
                subLoading: true,
            };
        case UPLOAD_APPLICATION_FILE_SUCCESS:
            return {
                ...state,
                subLoading: false,
                application: action.payload.application,
                message: "File uploaded successfully.",
            };
        case UPLOAD_APPLICATION_FILE_FAILURE:
            return {
                ...state,
                subLoading: false,
                error: action.payload.error,
            };
        case REMOVE_APPLICATION_FILE_STARTED:
            return {
                ...state,
                subLoading: true,
            };
        case REMOVE_APPLICATION_FILE_SUCCESS:
            return {
                ...state,
                subLoading: false,
                application: action.payload.application,
                message: "File removed successfully.",
            };
        case REMOVE_APPLICATION_FILE_FAILURE:
            return {
                ...state,
                subLoading: false,
                error: action.payload.error,
            };
        case GET_APPLICATION_FILE_URL_STARTED:
            return {
                ...state,
                subLoading: true,
            };
        case GET_APPLICATION_FILE_URL_SUCCESS:
            let newDocumentUrlObject = {}
            let fileKey = Object.keys(action.payload)[0];
            let fileValue = undefined;

            // Append new url to object of urls
            // identityDocuments & uploads are both arrays
            if (fileKey === "identityDocuments" || fileKey === "uploads") {
                fileValue = Object.values(action.payload)[0][Object.values(action.payload)[0].length - 1]
                let newIdentityDocArray = []
                if (state.applicationDocumentUrls[fileKey]) {
                    newIdentityDocArray = [...state.applicationDocumentUrls[fileKey]]
                }
                newIdentityDocArray[[Object.values(action.payload)[0].length - 1]] = fileValue
                newDocumentUrlObject = {...state.applicationDocumentUrls, [fileKey]: newIdentityDocArray}
            } else {
                fileValue = Object.values(action.payload)[0]
                newDocumentUrlObject = {...state.applicationDocumentUrls, [fileKey]: fileValue}
            }

            return {
                ...state,
                subLoading: false,
                applicationDocumentUrls: newDocumentUrlObject
            };
        case GET_APPLICATION_FILE_URL_FAILURE:
            return {
                ...state,
                subLoading: false,
                error: action.payload.error,
            };
        case SUBMIT_CANCELLATION_FORM_STARTED:
            return {
                ...state,
                subLoading: true,
            };
        case SUBMIT_CANCELLATION_FORM_SUCCESS:
            return {
                ...state,
                subLoading: false,
                application: { ...state.application, history: action.payload.application.history },
                message: "Cancellation form submitted successfully.",
            };
        case SUBMIT_CANCELLATION_FORM_FAILURE:
            return {
                ...state,
                subLoading: false,
                error: action.payload.error,
            };
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                applicationDeleted: false,
                applicationSubmitted: false,
                error: null,
                message: null,
            };
        default:
            return state;
    }
}
