import React from "react";
import "antd/dist/antd.min.css";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import Login from "./page/Login/Login";
import Navigation from "./layout/Navigation";
import Dashboard from "./page/Dashboard/Dashboard";
import ApplicationRoutes from "./page/Application/ApplicationRoutes";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
import { Profile } from "./page/Profile/Profile";

function App() {
    const { isAuthenticated } = useSelector((state) => state.auth);
    if (!isAuthenticated) {
        return (
            <Routes>
                <Route path="/*" element={<Login/>} />
            </Routes>
        );
    }

    return (
        <div className="App">
            <Navigation>
                <Routes>
                    <Route path="/" exact element={<Dashboard />} />
                    <Route path="application/*" element={<ApplicationRoutes />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route 
                        path="/*" 
                        element={
                            <Result 
                                status="404" 
                                title="404" 
                                subTitle="Sorry, the page you visited does not exist." 
                                extra={<Button type="primary">
                                    <Link to="/">
                                        Back Home
                                    </Link>
                                </Button>} 
                            />
                        }
                    />
                </Routes>
            </Navigation>
           
           
        </div>
    );
}

export default App;
