import React from "react";
import { List, Upload, Button, Popconfirm, Spin } from "antd";
import { LinkOutlined, UploadOutlined, InboxOutlined, DeleteTwoTone } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { uploadApplicationFile, removeApplicationFile, getApplicationFileUrl } from "../../../redux/actions/application/application";
import { ErrorMessage } from "../../common/ErrorMessage";
import {Loading} from "../../common/Loading"
const { Dragger } = Upload;

export const ApplicationDocuments = () => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const { application, applicationDocumentUrls, subLoading } = useSelector(state => state.application)

    const handleUploadFile = (file, fieldName, index) => {
        let fileFormData = new FormData();
        fileFormData.append("file", file);
        fileFormData.append("_id", application._id);
        fileFormData.append("fieldName", fieldName);
        if (fieldName === "identityDocuments" || fieldName === "uploads") {
            fileFormData.append("index", index);
        }
        dispatch(uploadApplicationFile(fileFormData));
    };

    const handleRemoveFile = (fieldName, index) => {
        let formData = new FormData();
        formData.append("_id", application._id);
        formData.append("fieldName", fieldName);
        formData.append("index", index);
        dispatch(removeApplicationFile(formData));
    };

    const handleGetFileUrl = (fileId, fieldName, index) => {
        dispatch(getApplicationFileUrl(fileId, fieldName, index))
    };

    return (
        <div>
            <List>
                {application.identityDocuments.length > 0 && application.identityDocuments.map((identityDocument, index) => (
                    <List.Item
                        actions={[identityDocument.identityDocumentUploadFile
                            &&
                            ((applicationDocumentUrls.identityDocuments && applicationDocumentUrls.identityDocuments[index]) ? <Button>
                                <a href={applicationDocumentUrls.identityDocuments[index].webViewLink} target="_blank" rel="noopener noreferrer">
                                    View Document <LinkOutlined />
                                </a>
                            </Button> : <Button
                                onClick={() => handleGetFileUrl(identityDocument.identityDocumentUploadFile, "identityDocuments", index)}
                                loading={subLoading}
                                disabled={subLoading}
                            >
                                Get URL
                            </Button>)
                        ]}
                        key={"identityDocument" + index}
                    >
                        <List.Item.Meta
                            description={`Identity Document ${index + 1}`}
                        />
                    </List.Item>
                ))}
                {application.legalDocument &&
                <List.Item
                    actions={[
                        applicationDocumentUrls.legalDocument ? <Button>
                            <a href={applicationDocumentUrls.legalDocument.webViewLink} target="_blank" rel="noopener noreferrer">
                                View Document <LinkOutlined />
                            </a>
                        </Button> : <Button
                            onClick={() => handleGetFileUrl(application.legalDocument, "legalDocument")}
                            loading={subLoading}
                            disabled={subLoading}
                        >
                            Get URL
                        </Button>
                    ]}
                    key={"legalDocument"}
                >
                    <List.Item.Meta
                        description="Legal Document"
                    />
                </List.Item>}
                {application.completedApplication && <List.Item
                    actions={[
                        applicationDocumentUrls.completedApplication ? <Button>
                            <a href={applicationDocumentUrls.completedApplication.webViewLink} target="_blank" rel="noopener noreferrer">
                                View Document <LinkOutlined />
                            </a>
                        </Button> : <Button
                            onClick={() => handleGetFileUrl(application.completedApplication, "completedApplication")}
                            loading={subLoading}
                            disabled={subLoading}
                        >
                            Get URL
                        </Button>
                    ]}
                    key={"completedApplication"}
                >
                    <List.Item.Meta
                        description="Completed Application"
                    />
                </List.Item>}
                {application.uploads.length > 0 && application.uploads.map((upload, index) => (
                    <List.Item
                        actions={[upload.fileId &&
                            <Popconfirm
                                title='Are you sure you want to delete this document?'
                                onConfirm={() => {
                                    handleRemoveFile(
                                        "uploads",
                                        index
                                    );
                                }}
                                okText='Yes'
                                cancelText='No'
                                icon={<DeleteTwoTone twoToneColor="#f5222d" />}
                            >
                                <Button
                                    type="link"
                                    style={{color: "#f5222d" }}
                                >
                                    <DeleteTwoTone twoToneColor='#f5222d' />
                                </Button>
                            </Popconfirm>,
                        upload.fileId &&
                            ((applicationDocumentUrls.uploads && applicationDocumentUrls.uploads[index]) ? <Button>
                                <a href={applicationDocumentUrls.uploads[index].webViewLink} target="_blank" rel="noopener noreferrer">
                                    View Document <LinkOutlined />
                                </a>
                            </Button> : <Button
                                onClick={() => handleGetFileUrl(upload.fileId, "uploads", index)}
                                loading={subLoading}
                                disabled={subLoading}
                            >
                                Get URL
                            </Button>
                            )
                        ]}
                        key={"uploads" + index}
                    >
                        <List.Item.Meta
                            description={`Uploads ${index + 1}`}
                        />
                    </List.Item>
                ))}
            </List>
            {subLoading?<Loading />:<Dragger
                fileList={[]}

                beforeUpload={file => {return false}}
                // showUploadList={false}
                onChange={(
                    e
                ) => {
                    if (
                        e.file.type ===
                            "image/*" ||
                        "application/pdf"
                    ) {
                        handleUploadFile(
                            e.file,
                            "uploads",
                            application.uploads.length
                        );
                    } else {
                        ErrorMessage(
                            "File has to be an image or pdf.",
                            null
                        );
                    }
                    return false;
                }}
            >
                <p>
                    <UploadOutlined />
                </p>
                <p>Click or drag files here to upload</p>
            </Dragger>}
        </div>
    );
};
