import React, { useState } from "react";
import {
    Form,
    Row,
    Col,
    Input,
    InputNumber,
    Select,
    Popover,
    AutoComplete,
    DatePicker,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
    formatPhoneNumber,
    validatePhoneNumber,
} from "../../../utils/FormatPhoneNumber/FormatPhoneNumber";
import FormButtons from "./FormButtons";
import { useSelector, useDispatch } from "react-redux";
import { saveApplication } from "../../../redux/actions/application/application";
import moment from "moment";
const { Option } = Select;

export default function BusinessInformationForm(props) {
    const formRef = React.createRef();
    const [isFormUpdated, setIsFormUpdated] = useState(false);
    const { application } = useSelector((state) => state.application);
    const dispatch = useDispatch();

    let allFormFields = {
        _id: "",
        createdBy: "",
        saleRepEmail: "",
        saleRepPhone: "",
        mid: "",
        type: "",
        currentProcessor: "",
        dbaName: "",
        businessPhone: "",
        businessType: "",
        contactName: "",
        website: "www.none",
        numberOfEmployee: 3,
        dbaAddress: {},
        email: null,
        googleLink: null,
        legalAddress: {},
        legalName: null,
        gstNum: null,
        mailingAddress: {},
        shippingAddress: {},
        yearEstablished: null,
        mccCode: "",
        annualSales: null,
        annualCreditCardVolume: null,
        annualInteracVolume: null,
        estAverageTransactionSize: null,
    };

    for (const [key, value] of Object.entries(allFormFields)) {
        if (application[key]) {
            allFormFields[key] = application[key];
            if (key === "yearEstablished" && application[key]) {
                allFormFields[key] = moment(application[key], "MMM YYYY");
            }
        }
    }

    const handleChangeYearEstablished = (value) => {
        console.log(value);
    };

    const saveForm = (form) => {
        if (application._id && isFormUpdated) {
            let formData = formRef.current.getFieldsValue();
            formData._id = application._id;
            setIsFormUpdated(false);
            formData = {
                ...formData,
                yearEstablished: formData["yearEstablished"]
                    ? formData["yearEstablished"].format("MMM YYYY")
                    : null,
            };

            dispatch(saveApplication(formData));
        }
    };

    return (
        <Form
            ref={formRef}
            layout='vertical'
            onFinish={saveForm}
            requiredMark='Required'
            initialValues={allFormFields}
            onFieldsChange={() => setIsFormUpdated(true)}
            scrollToFirstError
        >
            <FormButtons
                currentStep={props.currentStep}
                previousStep={props.previousStep}
                nextStep={props.nextStep}
                formRef={formRef}
                saveForm={saveForm}
            />
            <Row justify='space-between' style={{ width: "100%" }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={11} xxl={11}>
                    <Form.Item label='MID' name='mid'>
                        <Input allowClear />
                    </Form.Item>
                    <Form.Item
                        label='DBA Name'
                        name='dbaName'
                        required
                        rules={[
                            {
                                required: true,
                                message: "DBA Name is required!",
                            },
                        ]}
                    >
                        <Input allowClear />
                    </Form.Item>
                    <Form.Item
                        label='Type'
                        name='type'
                        required
                        rules={[
                            {
                                required: true,
                                message: "Type is required!",
                            },
                        ]}
                    >
                        <Select allowClear optionLabelProp='label'>
                            <Option value='First Data' label='First Data'>
                                <img
                                    src='/assets/logos/firstdata.svg'
                                    height={50}
                                    alt='First Data'
                                />
                            </Option>
                            <Option value='Elavon' label='Elavon'>
                                <img
                                    src='/assets/logos/elavon.svg'
                                    height={50}
                                    alt='Elavon'
                                />
                            </Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label='Current Processor'
                        name='currentProcessor'
                        required
                        rules={[
                            {
                                required: true,
                                message: "Current Processor is required.",
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    const type = getFieldValue("type");
                                    if (
                                        type === "Elavon" &&
                                        value ===
                                            "Elavon Under Diffirent Partner"
                                    ) {
                                        return Promise.reject(
                                            "Elavon does not tolerate resigns. This application cannot be created"
                                        );
                                    }
                                    if (
                                        type === "Elavon" &&
                                        value === "Unknown"
                                    ) {
                                        return Promise.reject(
                                            "Elavon does not tolerate resigns. Please make sure the processor of the current business is not Elavon before submiting this application."
                                        );
                                    }
                                    if (
                                        type === "First Data" &&
                                        value === "Unknown"
                                    ) {
                                        return Promise.reject(
                                            "FD does not tolerate resigns. Please make sure the processor of the current business is not First Data before submiting this application."
                                        );
                                    }
                                    if (
                                        type === "First Data" &&
                                        value ===
                                            "First Data Under Diffirent Partner"
                                    ) {
                                        return Promise.reject(
                                            "FD does not tolerate resigns. This application cannot be created."
                                        );
                                    }

                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <Select>
                            <Option value=''></Option>
                            <Option value='Elavon Under Diffirent Partner'>
                                Elavon Under Diffirent Partner
                            </Option>
                            <Option value='Elavon Under Dash Booking'>
                                Elavon Under Dash Booking
                            </Option>
                            <Option value='First Data Under Diffirent Partner'>
                                First Data Under Diffirent Partner
                            </Option>
                            <Option value='First Data Under Dash Booking'>
                                First Data Under Dash Booking
                            </Option>
                            <Option value='Other'>Other</Option>
                            <Option value='Unknown'>Unknown</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label='Google Link or Full Address'
                        name='googleLink'
                    >
                        <Input allowClear />
                    </Form.Item>
                    <Form.Item label='DBA Address'>
                        <Input.Group compact>
                            <Form.Item name={["dbaAddress", "streetAddress"]}>
                                <Input
                                    placeholder='Street Address'
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item name={["dbaAddress", "city"]}>
                                <Input placeholder='City' allowClear />
                            </Form.Item>
                            <Form.Item name={["dbaAddress", "province"]}>
                                <Select
                                    placeholder='Select province'
                                    allowClear
                                >
                                    <Option value='Alberta'>Alberta</Option>
                                    <Option value='British Columbia'>
                                        British Columbia
                                    </Option>
                                    <Option value='Manitoba'>Manitoba</Option>
                                    <Option value='Ontario'>Ontario</Option>
                                    <Option value='Quebec'>Quebec</Option>
                                    <Option value='Saskatchewan'>
                                        Saskatchewan
                                    </Option>
                                    <Option value='Prince Edward Island'>
                                        Prince Edward Island
                                    </Option>
                                    <Option value='Nova Scotia'>
                                        Nova Scotia
                                    </Option>
                                    <Option value='New Brunswick'>
                                        New Brunswick
                                    </Option>
                                    <Option value='Newfoundland and Labrador'>
                                        Newfoundland and Labrador
                                    </Option>
                                    <Option value='Northwest Territories'>
                                        Northwest Territories
                                    </Option>
                                    <Option value='Yukon'>Yukon</Option>
                                    <Option value='Nunavut'>Nunavut</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name={["dbaAddress", "postalCode"]}
                                normalize={(value) => value.toUpperCase()}
                            >
                                <Input placeholder='Postal Code' />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                    <Form.Item
                        label='Legal Name'
                        name='legalName'
                        required
                        rules={[
                            {
                                required: true,
                                message: "Legal name is required.",
                            },
                        ]}
                    >
                        <Input allowClear />
                    </Form.Item>
                    {application.type === "Elavon" && (
                        <Form.Item
                            label='GST Number'
                            name='gstNum'
                            required
                            rules={[
                                {
                                    required: true,
                                    message: "GST number is required.",
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>
                    )}
                    <Form.Item label='Legal Address (If different from DBA address)'>
                        <Input.Group compact>
                            <Form.Item name={["legalAddress", "streetAddress"]}>
                                <Input
                                    placeholder='Street Address'
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item name={["legalAddress", "city"]}>
                                <Input placeholder='City' allowClear />
                            </Form.Item>
                            <Form.Item name={["legalAddress", "province"]}>
                                <Select
                                    placeholder='Select province'
                                    allowClear
                                >
                                    <Option value='Alberta'>Alberta</Option>
                                    <Option value='British Columbia'>
                                        British Columbia
                                    </Option>
                                    <Option value='Manitoba'>Manitoba</Option>
                                    <Option value='Ontario'>Ontario</Option>
                                    <Option value='Quebec'>Quebec</Option>
                                    <Option value='Saskatchewan'>
                                        Saskatchewan
                                    </Option>
                                    <Option value='Prince Edward Island'>
                                        Prince Edward Island
                                    </Option>
                                    <Option value='Nova Scotia'>
                                        Nova Scotia
                                    </Option>
                                    <Option value='New Brunswick'>
                                        New Brunswick
                                    </Option>
                                    <Option value='Newfoundland and Labrador'>
                                        Newfoundland and Labrador
                                    </Option>
                                    <Option value='Northwest Territories'>
                                        Northwest Territories
                                    </Option>
                                    <Option value='Yukon'>Yukon</Option>
                                    <Option value='Nunavut'>Nunavut</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name={["legalAddress", "postalCode"]}
                                normalize={(value) => value.toUpperCase()}
                            >
                                <Input placeholder='Postal Code' allowClear />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                    <Form.Item
                        label='Business Type'
                        name='businessType'
                        required
                        rules={[
                            {
                                required: true,
                                message: "Business type is required.",
                            },
                        ]}
                    >
                        <Select allowClear placeholder='Select business type'>
                            <Option value='Sole proprietor'>
                                Sole proprietor
                            </Option>
                            <Option value='Partnership'>Partnership</Option>
                            <Option value='Non-Profit'>Non-Profit</Option>
                            <Option value='Public Corporation'>
                                Public Corporation
                            </Option>
                            <Option value='Private Corporation'>
                                Private Corporation
                            </Option>
                            <Option value='Government'>Government</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label='Business Phone'
                        name='businessPhone'
                        normalize={(value) => formatPhoneNumber(value)}
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || validatePhoneNumber(value)) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        "Please enter a valid phone number!"
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input placeholder='(xxx) xxx-xxxx' allowClear />
                    </Form.Item>
                    <Form.Item
                        label='Email'
                        name='email'
                        normalize={(value) => value.toLowerCase()}
                        rules={[
                            {
                                required: true,
                                message: "Email is required",
                            },
                            {
                                type: "email",
                                message: "Enter a valid email address.",
                            },
                        ]}
                    >
                        <Input allowClear />
                    </Form.Item>
                    <Form.Item
                        label='Contact Name'
                        name='contactName'
                        required
                        rules={[
                            () => ({
                                validator(_, value) {
                                    if (
                                        !!value &&
                                        value.trim().indexOf(" ") !== -1
                                    ) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        new Error(
                                            "The contact name must have firstname and lastname"
                                        )
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input allowClear />
                    </Form.Item>
                    <Form.Item
                        label='Year Established'
                        name='yearEstablished'
                        required
                        rules={[
                            {
                                required: true,
                                message: "Year established is required.",
                            },
                        ]}
                    >
                        <DatePicker
                            picker='month'
                            format='MMM YYYY'
                            onChange={(value) =>
                                handleChangeYearEstablished(value)
                            }
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={11} xxl={11}>
                    <Form.Item
                        label={
                            <>
                                <Popover
                                    content={
                                        <>
                                            <p>7230 for beauty salons</p>
                                            <p>5812 for restaurant</p>
                                            <p>5411 for groceries</p>
                                            <p>5691 for clothing stores</p>
                                        </>
                                    }
                                >
                                    MCC Code <InfoCircleOutlined />
                                </Popover>
                            </>
                        }
                        name='mccCode'
                        rules={[
                            {
                                len: 4,
                                message:
                                    "MCC Code must be exactly 4 characters.",
                            },
                        ]}
                    >
                        <AutoComplete
                            options={[
                                {
                                    value: "7230",
                                    label: (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            7230
                                            <span>Beauty Salons</span>
                                        </div>
                                    ),
                                },
                                {
                                    value: "5812",
                                    label: (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            5812
                                            <span>Restaurants</span>
                                        </div>
                                    ),
                                },
                                {
                                    value: "5411",
                                    label: (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            5411
                                            <span>Groceries</span>
                                        </div>
                                    ),
                                },
                                {
                                    value: "5691",
                                    label: (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            5691
                                            <span>Clothing Stores</span>
                                        </div>
                                    ),
                                },
                            ]}
                        >
                            <Input />
                        </AutoComplete>
                    </Form.Item>
                    <Form.Item
                        label='Annual Sales'
                        name='annualSales'
                        required
                        rules={[
                            {
                                required: true,
                                message: "Annual sales is required.",
                            },
                        ]}
                    >
                        <InputNumber
                            prefix='$'
                            formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                            onChange={(value) => {
                                if (value) {
                                    formRef.current.setFieldsValue({
                                        annualCreditCardVolume: (
                                            value * 0.75
                                        ).toFixed(0),
                                        annualInteracVolume: (
                                            value * 0.2
                                        ).toFixed(0),
                                    });
                                }
                            }}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <Form.Item
                        label='Annual Credit Card Volume'
                        name='annualCreditCardVolume'
                        required
                        rules={[
                            {
                                required: true,
                                message:
                                    "Annual credit card volume is required.",
                            },
                        ]}
                    >
                        <InputNumber
                            formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                            prefix='$'
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <Form.Item
                        label='Annual Interac Volume'
                        name='annualInteracVolume'
                        required
                        rules={[
                            {
                                required: true,
                                message: "Annual Interac volume is required.",
                            },
                        ]}
                    >
                        <InputNumber
                            formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                            prefix='$'
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <Form.Item
                        label='Est. Average Transaction Size'
                        name='estAverageTransactionSize'
                        required
                        rules={[
                            {
                                required: true,
                                message:
                                    "Est. Average Transaction Size is required.",
                            },
                        ]}
                    >
                        <InputNumber
                            formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                            prefix='$'
                            style={{ width: "100%" }}
                        />
                    </Form.Item>

                    <Form.Item
                        label='Mailing Address (If different from DBA address)'
                        name='mailingAddress'
                    >
                        <Input.Group compact>
                            <Form.Item
                                name={["mailingAddress", "streetAddress"]}
                            >
                                <Input
                                    placeholder='Street Address'
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item name={["mailingAddress", "city"]}>
                                <Input placeholder='City' allowClear />
                            </Form.Item>
                            <Form.Item name={["mailingAddress", "province"]}>
                                <Select
                                    placeholder='Select province'
                                    allowClear
                                >
                                    <Option value=''></Option>
                                    <Option value='Alberta'>Alberta</Option>
                                    <Option value='British Columbia'>
                                        British Columbia
                                    </Option>
                                    <Option value='Manitoba'>Manitoba</Option>
                                    <Option value='Ontario'>Ontario</Option>
                                    <Option value='Quebec'>Quebec</Option>
                                    <Option value='Saskatchewan'>
                                        Saskatchewan
                                    </Option>
                                    <Option value='Prince Edward Island'>
                                        Prince Edward Island
                                    </Option>
                                    <Option value='Nova Scotia'>
                                        Nova Scotia
                                    </Option>
                                    <Option value='New Brunswick'>
                                        New Brunswick
                                    </Option>
                                    <Option value='Newfoundland and Labrador'>
                                        Newfoundland and Labrador
                                    </Option>
                                    <Option value='Northwest Territories'>
                                        Northwest Territories
                                    </Option>
                                    <Option value='Yukon'>Yukon</Option>
                                    <Option value='Nunavut'>Nunavut</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name={["mailingAddress", "postalCode"]}
                                normalize={(value) => value.toUpperCase()}
                            >
                                <Input placeholder='Postal Code' allowClear />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>

                    <Form.Item label='Shipping Address (If different from DBA address)'>
                        <Input.Group compact>
                            <Form.Item
                                name={["shippingAddress", "streetAddress"]}
                            >
                                <Input
                                    placeholder='Street Address'
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item name={["shippingAddress", "city"]}>
                                <Input placeholder='City' allowClear />
                            </Form.Item>
                            <Form.Item name={["shippingAddress", "province"]}>
                                <Select
                                    placeholder='Select province'
                                    allowClear
                                >
                                    <Option value=''></Option>
                                    <Option value='Alberta'>Alberta</Option>
                                    <Option value='British Columbia'>
                                        British Columbia
                                    </Option>
                                    <Option value='Manitoba'>Manitoba</Option>
                                    <Option value='Ontario'>Ontario</Option>
                                    <Option value='Quebec'>Quebec</Option>
                                    <Option value='Saskatchewan'>
                                        Saskatchewan
                                    </Option>
                                    <Option value='Prince Edward Island'>
                                        Prince Edward Island
                                    </Option>
                                    <Option value='Nova Scotia'>
                                        Nova Scotia
                                    </Option>
                                    <Option value='New Brunswick'>
                                        New Brunswick
                                    </Option>
                                    <Option value='Newfoundland and Labrador'>
                                        Newfoundland and Labrador
                                    </Option>
                                    <Option value='Northwest Territories'>
                                        Northwest Territories
                                    </Option>
                                    <Option value='Yukon'>Yukon</Option>
                                    <Option value='Nunavut'>Nunavut</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name={["shippingAddress", "postalCode"]}
                                normalize={(value) => value.toUpperCase()}
                            >
                                <Input placeholder='Postal Code' allowClear />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                    <Form.Item label='Website' name='website'>
                        <Input allowClear />
                    </Form.Item>
                    <Form.Item
                        label='Number of Employees'
                        name='numberOfEmployee'
                    >
                        <Input type='number' />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}
