import { Routes, Route, Link } from "react-router-dom";
import NewApplicationPage from "./NewApplication/NewApplicationPage";
import EditApplicationPage from "./EditApplication/EditApplicationPage";
import ViewApplicationPage from "./ViewApplication/ViewApplicationPage";
import { Result, Button } from "antd";

function ApplicationRoutes(props) {
    return (
        <Routes>
            <Route 
                index 
                element={
                    <Result
                        title="Create a new application?"
                        extra={
                            <Button type="primary">
                                <Link to="/application/new">
                                    New Application
                                </Link>
                            </Button>
                        }
                    />
                }
            />
            <Route 
                path="/new" 
                element={<NewApplicationPage />} 
            />
            <Route 
                path="/edit" 
                element={<EditApplicationPage />} 
            />
            <Route 
                path="/view" 
                element={<ViewApplicationPage />} 
            />
            <Route 
                path="/*" 
                element={
                    <Result 
                        status="404" 
                        title="404" 
                        subTitle="Sorry, the page you visited does not exist." 
                        extra={<Button type="primary">
                            <Link to="/">
                                Back Home
                            </Link>
                        </Button>} 
                    />
                }
            />
        </Routes>
    )
}

export default ApplicationRoutes;