import { useEffect } from "react";
import { Typography, Tabs, Button, Space, Popconfirm } from "antd";
import { DeleteTwoTone, EditOutlined } from "@ant-design/icons"
import styles from "./ViewApplicationPage.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
    getApplication,
    deleteComment,
    deleteApplication
} from "../../../redux/actions/application/application";
import { useSearchParams } from "react-router-dom";
import { Loading } from "../../../components/common/Loading";
import { ErrorMessage } from "../../../components/common/ErrorMessage";
import ApplicationOutline from "../../../components/Application/ViewApplication/ApplicationOutline";
import { ApplicationStatus } from "../../../components/Application/ApplicationStatus";
import { ApplicationNotes } from "../../../components/Application/ViewApplication/ApplicationNotes";
import { CommentList } from "../../../components/Application/ViewApplication/CommentList";
import { useNavigate } from "react-router-dom";
import { ApplicationStatusHistory } from "../../../components/Application/ViewApplication/ApplicationStatusHistory";
import { ApplicationDocuments } from "../../../components/Application/ViewApplication/ApplicationDocuments";
import { ApplicationSalesRep } from "../../../components/Application/ViewApplication/ApplicationSalesRep"
import { ApplicationCancel } from "../../../components/Application/ViewApplication/ApplicationCancel";
const { Title } = Typography;
const { TabPane } = Tabs;
function ViewApplicationPage(props) {
    const [searchParams] = useSearchParams();
    let navigate = useNavigate();
    const { application, loading, error, message, applicationDeleted } = useSelector(
        (state) => state.application
    );

    const { user } = useSelector(state => state.auth)
    const dispatch = useDispatch();

    useEffect(() => {
        const applicationId = searchParams.get("id");

        dispatch(getApplication(applicationId));
    }, []);

    useEffect(() => {
        if (error || message) {
            return ErrorMessage(error, message);
        }

    }, [application, error, message]);

    useEffect(() => {
        if (applicationDeleted) {
            return navigate("/")
        }
    }, [applicationDeleted])

    if (loading || !application) {
        return <Loading />;
    }

    const handleDeleteComment = (commentId) => {
        dispatch(deleteComment(application._id, commentId));

    };

    const handleDeleteApplication = () => {
        dispatch(deleteApplication(application._id))
    }

    const allowCancelStatus = ["Approved", "From Amigo"]

    return (
        <div className={styles.container}>
            <div>
                {application.type === "First Data" && (
                    <img
                        src='/assets/logos/firstdata.svg'
                        style={{ margin: "0px", float: "right" }}
                        alt='First Data'
                        width={150}
                    />
                )}
                {application.type === "Elavon" && (
                    <img
                        src='/assets/logos/elavon.svg'
                        width={150}
                        style={{ margin: "0px", float: "right" }}
                        alt='Elavon'
                    />
                )}
                <Title level={2}>{application.dbaName}
                    {(application.status === "Draft" || user.role === "admin" || user.role === "developer") && <Button
                        type="link"
                        href={`/application/edit?id=` + application._id}
                    >
                        <EditOutlined /> Edit Application
                    </Button>}
                </Title>

                <Title level={5}>
                    Status: <ApplicationStatus status={application.status} />
                    {application.status === "Draft" && (
                        <Popconfirm
                            title='Are you sure you want to delete this application?'
                            onConfirm={handleDeleteApplication}
                            okText='Yes'
                            cancelText='No'
                            icon={<DeleteTwoTone twoToneColor='#f5222d' />}
                        >
                            <Button
                                type="text"
                                style={{ color: '#f5222d', marginLeft: "10px" }}
                            >
                                <DeleteTwoTone twoToneColor='#f5222d' /> Delete
                            </Button>
                        </Popconfirm>
                    )}
                </Title>

                <Tabs defaultActiveKey='1' size='large'>
                    <TabPane
                        tab={
                            <span>
                                {/* <AppleOutlined /> */}
                                Application
                            </span>
                        }
                        key='1'
                    >
                        <ApplicationOutline application={application} />
                    </TabPane>
                    <TabPane tab={<span>Status/History</span>} key='3'>
                        <ApplicationStatusHistory />
                    </TabPane>
                    <TabPane tab={<span>Documents</span>} key='4'>
                        <ApplicationDocuments />
                    </TabPane>
                    <TabPane tab={<span>Comments</span>} key='2'>
                        <ApplicationNotes />
                        <CommentList
                            comments={application.comments}
                            handleDeleteComment={handleDeleteComment}
                        />
                    </TabPane>
                    {["admin", "developer"].includes(user.role) && <TabPane tab={<span>Sales Rep</span>} key='5'>
                        <ApplicationSalesRep application={application} user={user} />
                    </TabPane>}
                    {allowCancelStatus.includes(application.status) && <TabPane tab={<span>Cancel</span>} key='6'>
                        <ApplicationCancel application={application} />
                    </TabPane>}
                </Tabs>
            </div>
        </div>
    );
}

export default ViewApplicationPage;
