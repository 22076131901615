import React, { useState } from "react";
import { Form, Row, Col, InputNumber, Checkbox, Divider } from "antd";
import FormButtons from "./FormButtons";
import { useSelector, useDispatch } from "react-redux";
import { saveApplication } from "../../../redux/actions/application/application";

const rateInputStyle = {
    minWidth: 200,
};

const feeInputStyle = {
    minWidth: 50,
};

const plainOptions = [
    "Visa",
    "Master Card",
    "Visa Debit",
    "InteracFlash",
    "Interac",
    "Amex/JCB",
    "Discover",
];
const defaultCheckedList = [
    "Visa",
    "Master Card",
    "Visa Debit",
    "InteracFlash",
];

export default function PricingForm(props) {
    const formRef = React.createRef();
    const [isFormUpdated, setIsFormUpdated] = useState(false);

    const { application } = useSelector((state) => state.application);

    const [checkedList, setCheckedList] = React.useState(application.cardTypes);
    const [indeterminate, setIndeterminate] = React.useState(true);
    const [checkAll, setCheckAll] = React.useState(false);
    const onChangeCardType = (list) => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < plainOptions.length);
        setCheckAll(list.length === plainOptions.length);
    };
    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? plainOptions : []);
        if (e.target.checked) {
            formRef.current.setFieldsValue({ cardTypes: plainOptions });
        } else {
            formRef.current.setFieldsValue({ cardTypes: [] });
        }
        setIsFormUpdated(true);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    let allFormFields = {
        cardTypes: ["Visa", "Master Card", "Visa Debit", "InteracFlash"],
        creditTransFee: null,
        creditRate: null,
        masterCardRate: null,
        masterCardTransFee: null,
        visaRate: null,
        visaTransFee: null,
        amexJcbRate: null,
        amexJcbTransFee: null,
        discoverRate: null,
        discoverTransFee: null,
        interacApprovedTransFee: null,
        interacDeclinedTransFee: 0,
        annualFee: 0,
        applicationFee: null,
        chargeBackBilling: 25,
        minDiscountFee: 25,
        monthlyFee: 0,
        quarterlyComplianceFee: 29.95,
        annualComplianceFee: 120,
        statementFee: 5,
    };

    for (const [key, value] of Object.entries(allFormFields)) {
        if (
            application.hasOwnProperty(key) &&
            (application[key] || application[key] === 0)
        ) {
            allFormFields[key] = application[key];
        }
    }
    const dispatch = useDispatch();

    const saveForm = () => {
        if (isFormUpdated && application._id) {
            let formData = formRef.current.getFieldsValue();
            formData._id = application._id;
            setIsFormUpdated(false);
            dispatch(saveApplication(formData));
        }
    };

    const updateCreditTransFee = (value) => {
        const fieldNames = [
            {
                name: "Visa",
                fieldName: "visaTransFee",
            },
            {
                name: "Master Card",
                fieldName: "masterCardTransFee",
            },
            {
                name: "Amex/JCB",
                fieldName: "amexJcbTransFee",
            },
            {
                name: "Discover",
                fieldName: "discoverTransFee",
            },
        ];
        checkedList.forEach((cardType) => {
            const foundFieldName = fieldNames.filter(
                (field) => field.name === cardType
            )[0];
            if (foundFieldName && foundFieldName.fieldName) {
                const updatedField = foundFieldName.fieldName;
                formRef.current.setFieldsValue({
                    [updatedField]: value,
                });
            }
        });
    };

    const updateCreditRate = (value) => {
        const fieldNames = [
            {
                name: "Visa",
                fieldName: "visaRate",
            },
            {
                name: "Master Card",
                fieldName: "masterCardRate",
            },
            {
                name: "Amex/JCB",
                fieldName: "amexJcbRate",
            },
            {
                name: "Discover",
                fieldName: "discoverRate",
            },
        ];
        checkedList.forEach((cardType) => {
            const foundFieldName = fieldNames.filter(
                (field) => field.name === cardType
            )[0];
            if (foundFieldName && foundFieldName.fieldName) {
                const updatedField = foundFieldName.fieldName;
                formRef.current.setFieldsValue({
                    [updatedField]: value,
                });
            }
        });
    };

    return (
        <Form
            ref={formRef}
            layout='vertical'
            onFinish={saveForm}
            requiredMark='Required'
            initialValues={allFormFields}
            onFieldsChange={() => setIsFormUpdated(true)}
            scrollToFirstError
        >
            <FormButtons
                currentStep={props.currentStep}
                previousStep={props.previousStep}
                nextStep={props.nextStep}
                saveForm={saveForm}
                formRef={formRef}
            />

            <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
                style={{ marginBottom: "10px" }}
            >
                Check all Types
            </Checkbox>
            <br />
            <Form.Item
                name='cardTypes'
                rules={[
                    {
                        required: true,
                        message: "Please select card type!",
                        type: "array",
                    },
                ]}
            >
                <Checkbox.Group
                    onChange={onChangeCardType}
                    style={{ width: "100%" }}
                >
                    <Row justify='space-between'>
                        <Col>
                            <Checkbox
                                value='Visa'
                                style={{ lineHeight: "32px" }}
                            >
                                Visa
                            </Checkbox>
                        </Col>
                        <Col>
                            <Checkbox
                                value='Master Card'
                                style={{ lineHeight: "32px" }}
                            >
                                Master Card
                            </Checkbox>
                        </Col>
                        <Col>
                            <Checkbox
                                value='Visa Debit'
                                style={{ lineHeight: "32px" }}
                            >
                                Visa Debit
                            </Checkbox>
                        </Col>
                        <Col>
                            <Checkbox
                                value='InteracFlash'
                                style={{ lineHeight: "32px" }}
                            >
                                InteracFlash
                            </Checkbox>
                        </Col>
                        <Col>
                            <Checkbox
                                value='Interac'
                                style={{ lineHeight: "32px" }}
                            >
                                Interac
                            </Checkbox>
                        </Col>
                        <Col>
                            <Checkbox
                                value='Amex/JCB'
                                style={{ lineHeight: "32px" }}
                            >
                                Amex/JCB
                            </Checkbox>
                        </Col>
                        <Col>
                            <Checkbox
                                value='Discover'
                                style={{ lineHeight: "32px" }}
                            >
                                Discover
                            </Checkbox>
                        </Col>
                    </Row>
                </Checkbox.Group>
            </Form.Item>

            <Divider />
            <Row style={{ width: "100%" }}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Row>
                        <Col style={{ marginRight: "10px" }}>
                            <Form.Item
                                label='Credit Rate'
                                name='creditRate'
                                // required
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: "Credit rate is required.",
                                //     },
                                // ]}
                            >
                                <InputNumber
                                    min='0'
                                    max='1'
                                    step='0.1'
                                    onChange={(value) => {
                                        updateCreditRate(value);
                                    }}
                                    // onChange={(value) =>
                                    //     formRef.current.setFieldsValue({
                                    //         masterCardRate: value,
                                    //         visaRate: value,
                                    //     })
                                    // }
                                    style={rateInputStyle}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label='Credit Trans Fee'
                                name='creditTransFee'
                                // required
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: "Credit trans fee is required.",
                                //     },
                                // ]}
                            >
                                <InputNumber
                                    prefix='$'
                                    min='0'
                                    step='0.01'
                                    controls={false}
                                    onChange={(value) =>
                                        updateCreditTransFee(value)
                                    }
                                    // onChange={(value) =>
                                    //     formRef.current.setFieldsValue({
                                    //         masterCardTransFee: value,
                                    //         visaTransFee: value,
                                    //     })
                                    // }
                                    style={feeInputStyle}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {(checkedList.includes("Visa") ||
                        checkedList.includes("Visa Debit")) && (
                        <Row>
                            <Col style={{ marginRight: "10px" }}>
                                <Form.Item
                                    label={
                                        <>
                                            <img
                                                style={{ width: 20 }}
                                                src={"/assets/logos/visa.svg"}
                                                alt='visa-logo'
                                            />{" "}
                                            &nbsp; Visa Rate&nbsp;
                                        </>
                                    }
                                    name='visaRate'
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message: "Visa rate is required.",
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        min='0'
                                        max='1'
                                        step='0.01'
                                        style={rateInputStyle}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    label={
                                        <>
                                            Trans Fee&nbsp;{" "}
                                            <img
                                                style={{ width: 20 }}
                                                src={"/assets/logos/visa.svg"}
                                                alt='visa-logo'
                                            />
                                        </>
                                    }
                                    name='visaTransFee'
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Visa trans fee is required.",
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        prefix='$'
                                        min='0'
                                        step='0.01'
                                        controls={false}
                                        style={feeInputStyle}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    {checkedList.includes("Master Card") && (
                        <Row>
                            <Col style={{ marginRight: "10px" }}>
                                <Form.Item
                                    label={
                                        <>
                                            <img
                                                style={{ width: 20 }}
                                                src={
                                                    "/assets/logos/mastercard.svg"
                                                }
                                                alt='mastercard-logo'
                                            />
                                            &nbsp; Mastercard Rate&nbsp;
                                        </>
                                    }
                                    name='masterCardRate'
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Master card rate is required.",
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        min='0'
                                        max='1'
                                        step='0.01'
                                        style={rateInputStyle}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    label={
                                        <>
                                            Trans Fee&nbsp;
                                            <img
                                                style={{ width: 20 }}
                                                src={
                                                    "/assets/logos/mastercard.svg"
                                                }
                                                alt='mastercard-logo'
                                            />
                                        </>
                                    }
                                    name='masterCardTransFee'
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Master Card Trans Fee is required.",
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        prefix='$'
                                        min='0'
                                        step='0.01'
                                        controls={false}
                                        style={feeInputStyle}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    {checkedList.includes("Amex/JCB") && (
                        <Row>
                            <Col style={{ marginRight: "10px" }}>
                                <Form.Item
                                    label={
                                        <>
                                            <img
                                                style={{ width: 20 }}
                                                src={"/assets/logos/amex.svg"}
                                                alt='amex-logo'
                                            />{" "}
                                            &nbsp; Amex/JCB Rate&nbsp;
                                        </>
                                    }
                                    name='amexJcbRate'
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Amex/JCB rate is required.",
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        min='0'
                                        max='1'
                                        step='0.01'
                                        style={rateInputStyle}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    label={
                                        <>
                                            Trans Fee&nbsp;
                                            <img
                                                style={{ width: 20 }}
                                                src={"/assets/logos/amex.svg"}
                                                alt='amex-logo'
                                            />
                                        </>
                                    }
                                    name='amexJcbTransFee'
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Amex/JCB trans fee is required.",
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        prefix='$'
                                        min='0'
                                        step='0.01'
                                        controls={false}
                                        style={feeInputStyle}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    {checkedList.includes("Discover") && (
                        <Row>
                            <Col style={{ marginRight: "10px" }}>
                                <Form.Item
                                    label={
                                        <>
                                            <img
                                                style={{ width: 20 }}
                                                src={
                                                    "/assets/logos/discover.svg"
                                                }
                                                alt='discover-logo'
                                            />
                                            &nbsp; Discover Rate
                                        </>
                                    }
                                    name='discoverRate'
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Discover rate is required.",
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        min='0'
                                        max='1'
                                        step='0.01'
                                        style={rateInputStyle}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    label={
                                        <>
                                            Trans Fee&nbsp;
                                            <img
                                                style={{ width: 20 }}
                                                src={
                                                    "/assets/logos/discover.svg"
                                                }
                                                alt='discover-logo'
                                            />
                                        </>
                                    }
                                    name='discoverTransFee'
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Discover trans fee is required.",
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        prefix='$'
                                        min='0'
                                        step='0.01'
                                        controls={false}
                                        style={feeInputStyle}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                </Col>
                {(checkedList.includes("InteracFlash") ||
                    checkedList.includes("Interac")) && (
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item
                            label={
                                <>
                                    Interac Approved Trans Fee&nbsp;
                                    <img
                                        style={{ width: 20 }}
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/assets/logos/interac.svg"
                                        }
                                        alt='interac-logo'
                                    />
                                </>
                            }
                            name='interacApprovedTransFee'
                            required
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Interac approved trans fee is required.",
                                },
                            ]}
                        >
                            <InputNumber
                                prefix='$'
                                min='0'
                                step='0.01'
                                controls={false}
                                style={feeInputStyle}
                            />
                        </Form.Item>
                        <Form.Item
                            label={
                                <>
                                    Interac Declined Trans Fee&nbsp;
                                    <img
                                        style={{ width: 20 }}
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/assets/logos/interac.svg"
                                        }
                                        alt='interac-logo'
                                    />
                                </>
                            }
                            name='interacDeclinedTransFee'
                            required
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Interac declined trans fee is required.",
                                },
                            ]}
                        >
                            <InputNumber
                                prefix='$'
                                min='0'
                                step='0.01'
                                controls={false}
                                style={feeInputStyle}
                            />
                        </Form.Item>
                    </Col>
                )}
                <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Form.Item
                        label='Annual Fee'
                        name='annualFee'
                        required
                        rules={[
                            {
                                required: true,
                                message: "Annual fee is required.",
                            },
                        ]}
                    >
                        <InputNumber
                            prefix='$'
                            min='0'
                            step='0.01'
                            controls={false}
                            style={feeInputStyle}
                        />
                    </Form.Item>
                    <Form.Item
                        label='Application Fee'
                        name='applicationFee'
                        required
                        rules={[
                            {
                                required: true,
                                message: "Application fee is required.",
                            },
                        ]}
                    >
                        <InputNumber
                            prefix='$'
                            min='0'
                            step='10'
                            controls={false}
                            style={feeInputStyle}
                        />
                    </Form.Item>
                    <Form.Item
                        label='Chargeback Billing'
                        name='chargeBackBilling'
                        rules={[
                            {
                                required: true,
                                message: "Chargeback Billing is required.",
                            },
                        ]}
                    >
                        <InputNumber
                            prefix='$'
                            min='25'
                            step='1'
                            controls={false}
                            style={feeInputStyle}
                        />
                    </Form.Item>
                    <Form.Item
                        label='Min Discount Fee'
                        name='minDiscountFee'
                        required
                        rules={[
                            {
                                required: true,
                                message: "Min. discount fee is required.",
                            },
                        ]}
                    >
                        <InputNumber
                            prefix='$'
                            min='25'
                            step='5'
                            controls={false}
                            style={feeInputStyle}
                        />
                    </Form.Item>
                    <Form.Item
                        label='Monthly Fee'
                        name='monthlyFee'
                        required
                        rules={[
                            {
                                required: true,
                                message: "Monthly fee is required.",
                            },
                        ]}
                    >
                        <InputNumber
                            prefix='$'
                            min='0'
                            step='5'
                            controls={false}
                            style={feeInputStyle}
                        />
                    </Form.Item>
                    {!["7230", "5812", "5411", "5691"].includes(
                        application.mccCode
                    ) ? (
                        <Form.Item
                            label='Annual Compliance Fee'
                            name='annualComplianceFee'
                            required
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Annual compliance fee is required. Min $120 (Previous: Quarterly Compliance Fee)",
                                },
                            ]}
                        >
                            <InputNumber
                                prefix='$'
                                min='120'
                                step='5'
                                controls={false}
                                style={feeInputStyle}
                            />
                        </Form.Item>
                    ) : (
                        <Form.Item
                            label='Quarterly Compliance Fee'
                            name='quarterlyComplianceFee'
                            required
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Quarterly compliance fee is required.",
                                },
                            ]}
                        >
                            <InputNumber
                                prefix='$'
                                min='29.95'
                                step='5'
                                controls={false}
                                style={feeInputStyle}
                            />
                        </Form.Item>
                    )}
                    <Form.Item
                        label='Statement Fee'
                        name='statementFee'
                        required
                        rules={[
                            {
                                required: true,
                                message: "Statement fee is required.",
                            },
                        ]}
                    >
                        <InputNumber
                            prefix='$'
                            min='5'
                            step='5'
                            controls={false}
                            style={feeInputStyle}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}
