import API from "../../../utils/API/API";

import {
    GET_APPLICATION_LIST_STARTED,
    GET_APPLICATION_LIST_SUCCESS,
    GET_APPLICATION_LIST_FAILURE,
    CREATE_APPLICATION_STARTED,
    CREATE_APPLICATION_SUCCESS,
    CREATE_APPLICATION_FAILURE,
    GET_APPLICATION_STARTED,
    GET_APPLICATION_SUCCESS,
    GET_APPLICATION_FAILURE,
    SAVE_APPLICATION_STARTED,
    SAVE_APPLICATION_SUCCESS,
    SAVE_APPLICATION_FAILURE,
    ADD_COMMENT_STARTED,
    ADD_COMMENT_SUCCESS,
    ADD_COMMENT_FAILURE,
    UPDATE_STATUS_STARTED,
    UPDATE_STATUS_SUCCESS,
    UPDATE_STATUS_FAILURE,
    DELETE_COMMENT_STARTED,
    DELETE_COMMENT_SUCCESS,
    DELETE_COMMENT_FAILURE,
    DELETE_APPLICATION_STARTED,
    DELETE_APPLICATION_SUCCESS,
    DELETE_APPLICATION_FAILURE,
    UPLOAD_APPLICATION_FILE_STARTED,
    UPLOAD_APPLICATION_FILE_SUCCESS,
    UPLOAD_APPLICATION_FILE_FAILURE,
    REMOVE_APPLICATION_FILE_STARTED,
    REMOVE_APPLICATION_FILE_SUCCESS,
    REMOVE_APPLICATION_FILE_FAILURE,
    GET_APPLICATION_FILE_URL_STARTED,
    GET_APPLICATION_FILE_URL_SUCCESS,
    GET_APPLICATION_FILE_URL_FAILURE,
    SUBMIT_APPLICATION_TO_ADMIN_STARTED,
    SUBMIT_APPLICATION_TO_ADMIN_SUCCESS,
    SUBMIT_APPLICATION_TO_ADMIN_FAILURE,
    UPDATE_SALES_REP_STARTED,
    UPDATE_SALES_REP_SUCCESS,
    UPDATE_SALES_REP_FAILURE,
    SUBMIT_CANCELLATION_FORM_STARTED,
    SUBMIT_CANCELLATION_FORM_SUCCESS,
    SUBMIT_CANCELLATION_FORM_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/application/application"
import { LOGOUT } from "../../constants/auth/auth";


export const updateSalesRep = (data) => {
    return dispatch => {
        dispatch(updateSalesRepStarted())
        API.patch(`/api/application/update-sales-rep`, data).then(res => {
            dispatch(updateSalesRepSuccess(res.data.application))
        }).catch(err => {
            catchError(err, dispatch, updateSalesRepFailure);
        }).finally(() => {
            setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 1000);
        });
    }
}

const updateSalesRepStarted = () => ({
    type: UPDATE_SALES_REP_STARTED
})

const updateSalesRepSuccess = data => ({
    type: UPDATE_SALES_REP_SUCCESS,
    payload: data
})

const updateSalesRepFailure = error => ({
    type: UPDATE_SALES_REP_FAILURE,
    payload: {error}
})


export const updateStatus = (data) => {
    return dispatch => {
        dispatch(updateStatusStarted())
        API.patch(`/api/application/update-status`, data).then(res => {
            dispatch(updateStatusSuccess(res.data))
        }).catch(err => {
            catchError(err, dispatch, updateStatusFailure);
        }).finally(() => {
            setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 1000);
        });
    }
}

const updateStatusStarted = () => ({
    type: UPDATE_STATUS_STARTED
})

const updateStatusSuccess = data => ({
    type: UPDATE_STATUS_SUCCESS,
    payload: data
})

const updateStatusFailure = error => ({
    type: UPDATE_STATUS_FAILURE,
    payload: {error}
})



export const addComment = (comment) => {
    return dispatch => {
        dispatch(addCommentStarted())
        API.post(`/api/application/add-comment`, comment).then(res => {
            dispatch(addCommentSuccess(res.data))
        }).catch(err => {
            catchError(err, dispatch, addCommentFailure);
        }).finally(() => {
            setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 1000);
        });
    }
}

const addCommentStarted = () => ({
    type: ADD_COMMENT_STARTED
})

const addCommentSuccess = data => ({
    type: ADD_COMMENT_SUCCESS,
    payload: data
})

const addCommentFailure = error => ({
    type: ADD_COMMENT_FAILURE,
    payload: {error}
})


export const deleteComment = (applicationId, commentId) => {
    return dispatch => {
        dispatch(deleteCommentStarted())
        API.delete(`/api/application/delete-comment?applicationId=${applicationId}&commentId=${commentId}`)
        .then(res => {
            dispatch(deleteCommentSuccess(commentId))
        }).catch(err => {
            catchError(err, dispatch, deleteCommentFailure);
        }).finally(() => {
            setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 1000);
        });
    }
}

const deleteCommentStarted = () => ({
    type: DELETE_COMMENT_STARTED
})

const deleteCommentSuccess = data => ({
    type: DELETE_COMMENT_SUCCESS,
    payload: data
})

const deleteCommentFailure = error => ({
    type: DELETE_COMMENT_FAILURE,
    payload: { error }
})


export const deleteApplication = (id) => {
    return dispatch => {
        dispatch(deleteApplicationStarted())
        API.delete(`/api/application/delete/${id}`).then(res => {
            dispatch(deleteApplicationSuccess(id))
        }).catch(err => {
            catchError(err, dispatch, deleteApplicationFailure);
        }).finally(() => {
            setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 1000);
        });
    }
}

const deleteApplicationStarted = () => ({
    type: DELETE_APPLICATION_STARTED
})

const deleteApplicationSuccess = data => ({
    type: DELETE_APPLICATION_SUCCESS,
    payload: data
})

const deleteApplicationFailure = error => ({
    type: DELETE_APPLICATION_FAILURE,
    payload: {error}
})


export const submitApplicationToAdmin = (id) => {
    return dispatch => {
        dispatch(submitApplicationToAdminStarted())
        API.post(`/api/application/submit-to-admin`, {id}).then(res => {
            dispatch(submitApplicationToAdminSuccess(res.data))
        }).catch(err => {
            catchError(err, dispatch, submitApplicationToAdminFailure);
        }).finally(() => {
            setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 1000);
        });
    }
}


const submitApplicationToAdminStarted = () => ({
    type: SUBMIT_APPLICATION_TO_ADMIN_STARTED
})

const submitApplicationToAdminSuccess = data => ({
    type: SUBMIT_APPLICATION_TO_ADMIN_SUCCESS,
    payload: data
})

const submitApplicationToAdminFailure = error => ({
    type: SUBMIT_APPLICATION_TO_ADMIN_FAILURE,
    payload: {error}
})

export const getApplicationList = (query) => {
    return dispatch => {
        dispatch(getApplicationListStarted())

        API.post("/api/application/list", query).then(res => {
            dispatch(getApplicationListSuccess(res.data))
        }).catch(err => {

            catchError(err, dispatch, getApplicationListFailure);
        }).finally(() => {
            setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 1000);
        });
    }
}

const getApplicationListStarted = () => ({
    type: GET_APPLICATION_LIST_STARTED
})

const getApplicationListSuccess = data => ({
    type: GET_APPLICATION_LIST_SUCCESS,
    payload: data
})

const getApplicationListFailure = error => ({
    type: GET_APPLICATION_LIST_FAILURE,
    payload: {error}
})


export const createApplication = (application) => {
    return dispatch => {
        dispatch(createApplicationStarted())

        API.post(`/api/application/create`, application).then(res => {
            dispatch(createApplicationSuccess(res.data))
        }).catch(err => {
            catchError(err, dispatch, createApplicationFailure);
        }).finally(() => {
            setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 1000);
        });
    }
}

const createApplicationStarted = () => ({
    type: CREATE_APPLICATION_STARTED
})

const createApplicationSuccess = data => ({
    type: CREATE_APPLICATION_SUCCESS,
    payload: data
})

const createApplicationFailure = error => ({
    type: CREATE_APPLICATION_FAILURE,
    payload: { error }
})


export const getApplication = (id) => {
    return dispatch => {
        dispatch(getApplicationStarted())

        API.get(`/api/application?id=${id}`).then(res => {
            dispatch(getApplicationSuccess(res.data))
        }).catch(err => {

            catchError(err, dispatch, getApplicationFailure);
        }).finally(() => {
            setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 1000);
        });
    }
}

const getApplicationStarted = () => ({
    type: GET_APPLICATION_STARTED
})

const getApplicationSuccess = data => ({
    type: GET_APPLICATION_SUCCESS,
    payload: data
})

const getApplicationFailure = error => ({
    type: GET_APPLICATION_FAILURE,
    payload: {error}
})


export const saveApplication = (data) => {
    return dispatch => {
        dispatch(saveApplicationStarted())

        API.post(`/api/application/save`, data).then(res => {
            dispatch(saveApplicationSuccess(res.data))
        }).catch(err => {

            catchError(err, dispatch, saveApplicationFailure);
        }).finally(() => {
            setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 1000);
        });
    }

}

const saveApplicationStarted = () => ({
    type: SAVE_APPLICATION_STARTED
})

const saveApplicationSuccess = data => ({
    type: SAVE_APPLICATION_SUCCESS,
    payload: data
})

const saveApplicationFailure = error => ({
    type: SAVE_APPLICATION_FAILURE,
    payload: {error}
})


export const uploadApplicationFile = (formData) => {
    return dispatch => {
        dispatch(uploadApplicationFileStarted())

        API.patch(`/api/application/upload-file`, formData).then(res => {
            dispatch(uploadApplicationFileSuccess(res.data))
        }).catch(err => {
            catchError(err, dispatch, uploadApplicationFileFailure);
        }).finally(() => {
            setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 1000);
        });
    }
}

const uploadApplicationFileStarted = () => ({
    type: UPLOAD_APPLICATION_FILE_STARTED
})

const uploadApplicationFileSuccess = data => ({
    type: UPLOAD_APPLICATION_FILE_SUCCESS,
    payload: data
})

const uploadApplicationFileFailure = error => ({
    type: UPLOAD_APPLICATION_FILE_FAILURE,
    payload: { error }
})


export const removeApplicationFile = (formData) => {
    return dispatch => {
        dispatch(removeApplicationFileStarted())

        API.patch(`/api/application/remove-file`, formData).then(res => {
            dispatch(removeApplicationFileSuccess(res.data))
        }).catch(err => {
            catchError(err, dispatch, removeApplicationFileFailure);
        }).finally(() => {
            setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 1000);
        });
    }
}

const removeApplicationFileStarted = () => ({
    type: REMOVE_APPLICATION_FILE_STARTED
})

const removeApplicationFileSuccess = data => ({
    type: REMOVE_APPLICATION_FILE_SUCCESS,
    payload: data
})

const removeApplicationFileFailure = error => ({
    type: REMOVE_APPLICATION_FILE_FAILURE,
    payload: { error }
})


export const getApplicationFileUrl = (fileId, fieldName, index) => {
    return dispatch => {
        dispatch(getApplicationFileUrlStarted())

        API.get(`/api/application/get-file-url?fileId=${fileId}&fieldName=${fieldName}&index=${index}`).then(res => {
            dispatch(getApplicationFileUrlSuccess(res.data))
        }).catch(err => {
            catchError(err, dispatch, getApplicationFileUrlFailure);
        }).finally(() => {
            setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 1000);
        });
    }
}

const getApplicationFileUrlStarted = () => ({
    type: GET_APPLICATION_FILE_URL_STARTED
})

const getApplicationFileUrlSuccess = data => ({
    type: GET_APPLICATION_FILE_URL_SUCCESS,
    payload: data
})

const getApplicationFileUrlFailure = error => ({
    type: GET_APPLICATION_FILE_URL_FAILURE,
    payload: { error }
})


export const submitCancellationForm = (formData) => {
    return dispatch => {
        dispatch(submitCancellationFormStarted())
        API.post(`/api/application/submit-cancellation-request`, formData).then(res => {
            dispatch(submitCancellationFormSuccess(res.data))
        }).catch(err => {
            catchError(err, dispatch, submitCancellationFormFailure);
        }).finally(() => {
            setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 1000);
        });
    }
}

const submitCancellationFormStarted = () => ({
    type: SUBMIT_CANCELLATION_FORM_STARTED
})

const submitCancellationFormSuccess = data => ({
    type: SUBMIT_CANCELLATION_FORM_SUCCESS,
    payload: data
})

const submitCancellationFormFailure = error => ({
    type: SUBMIT_CANCELLATION_FORM_FAILURE,
    payload: { error }
})


const logoutUser = () => ({
    type: LOGOUT,
});


const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
});



//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;

    if (error.response) {
        message = error.response.data.message?? error.message;
        if (error.response.status === 401 || error.response.status === 403) {
            dispatch(logoutUser());
        }
    }


    dispatch(nextFunction(message));
};
