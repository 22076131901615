import React, { useEffect } from "react";
import { Descriptions, Form, Input, Button, Divider, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {MailOutlined, PhoneOutlined, UserOutlined} from "@ant-design/icons"
import { Loading } from "../../components/common/Loading";
import { updatePassword } from "../../redux/actions/auth/auth";
import {ErrorMessage} from "../../components/common/ErrorMessage";
const PASSWORD_REGEX =  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/
export const Profile = () => {
    let { user, error, loading } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    if (!user) {
        user = localStorage.getItem("user");
        if (user) {
            user = JSON.parse(user);
        }
    }
  

    useEffect(() => {
      if (error) {
        return ErrorMessage(error, null);
      }
  }, [error]);

  if (!user) {
    return <Loading />;
}

    const onFinish = (values) => {
        dispatch(updatePassword(values));
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <div style={{ height: "100%", overflow: "scroll" }}>
            <Descriptions title="User Info" bordered>
                <Descriptions.Item label={<UserOutlined />}>{user.name}</Descriptions.Item>
                <Descriptions.Item label={<MailOutlined />}>
                    {user.email}
                </Descriptions.Item>
                <Descriptions.Item label={<PhoneOutlined />}>
                    {user.phone}
                </Descriptions.Item>
                <Descriptions.Item label="Title">
                    <Tag color="#eb2f96">{user.title}</Tag>
                </Descriptions.Item>
                {user.role === "admin" && <Descriptions.Item label="Role">{user.role}</Descriptions.Item>}
            </Descriptions>
            <Divider />
            <h1>Change Password</h1>
            <Form
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Current Password"
                    name="currentPassword"
                    rules={[
                        {
                            required: true,
                            message: "Please input your current password!",
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label="New Password"
                    name="newPassword"
                    rules={[
                        {
                            required: true,
                            message: "Please input your new password!",
                        },
                        ({ getFieldValue }) => ({
                            validator: async (_, npw) => {
                                if (getFieldValue("currentPassword") === npw) {
                                    return Promise.reject(
                                        new Error(
                                            "New password should be different from current password!"
                                        )
                                    );
                                }
                                if (!PASSWORD_REGEX.test(npw)) {
                                    return Promise.reject(
                                        new Error(
                                            "Password does not meet the requirements"
                                        )
                                    );
                                }
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label="Confimed Password"
                    name="repeatPassword"
                    rules={[
                        {
                            required: true,
                            message: "Please input your new password!",
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (
                                    !value ||
                                    getFieldValue("newPassword") ===
                                    value
                                ) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error(
                                        "The two passwords that you entered do not match!"
                                    )
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <h3>Password requirements:</h3>
                                <ul>
                                    <li>
                                        Contain at least 1 lowercase
                                        alphabetical character
                                    </li>
                                    <li>
                                        Contain at least 1 uppercase
                                        alphabetical character
                                    </li>
                                    <li>
                                        Contain at least 1 numeric character
                                    </li>
                                    <li>Must be 8 characters or longer</li>
                                </ul>

                <Form.Item>
                    <Button
                        style={{ borderRadius: "5px" }}
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};
