import React, { useState, useEffect } from "react";
import { Form, Row, Col, Input, Select, Upload, Button } from "antd";
import FormButtons from "./FormButtons";
import {
    formatPhoneNumber,
    validatePhoneNumber,
} from "../../../utils/FormatPhoneNumber/FormatPhoneNumber";
import { useSelector, useDispatch } from "react-redux";
import {
    saveApplication,
    uploadApplicationFile,
    removeApplicationFile,
} from "../../../redux/actions/application/application";
import {
    MinusCircleTwoTone,
    PlusOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import { ErrorMessage } from "../../common/ErrorMessage";

const { Option } = Select;

export default function OwnerForm(props) {
    const formRef = React.createRef();
    const [isFormUpdated, setIsFormUpdated] = useState(false);
    const { application, subLoading } = useSelector(
        (state) => state.application
    );
    const dispatch = useDispatch();

    const [identityDocuments, setIdentityDocuments] = useState([{}, {}]);
    const [isUploadDisabledArray, setIsUploadDisabledArray] = useState([
        true,
        true,
    ]);

    let allFormFields = {
        fullName: null,
        title: "Owner",
        ownerPhone: "",
        ownerAddress: {},
        dobDay: "",
        dobMonth: "",
        dobYear: "",
        sinNum: null,
        ownerEmail: "",
        percentageOwned: 100,
        identityDocuments: identityDocuments,
    };

    useEffect(() => {
        let tempIdentityDocs = application.identityDocuments;
        if (application.identityDocuments.length === 0) {
            tempIdentityDocs.push({
                identityDocumentType: undefined,
                identityDocumentUploadFile: "",
            });
            tempIdentityDocs.push({
                identityDocumentType: undefined,
                identityDocumentUploadFile: "",
            });
        } else if (application.identityDocuments.length === 1) {
            tempIdentityDocs.push({
                identityDocumentType: undefined,
                identityDocumentUploadFile: "",
            });
        }
        let newUploadDisabledArray = [...isUploadDisabledArray];
        for (let i = 0; i < tempIdentityDocs.length; i++) {
            newUploadDisabledArray[i] = tempIdentityDocs[i]
                .identityDocumentType
                ? false
                : true;
        }
        setIsUploadDisabledArray(newUploadDisabledArray);
        setIdentityDocuments(tempIdentityDocs);

        for (const [key, value] of Object.entries(allFormFields)) {
            if (application.hasOwnProperty(key)) {
                allFormFields[key] = application[key];
                formRef.current.setFieldsValue(allFormFields);
            }
        }

        // formRef.current.setFieldsValue({allFormFields})
    }, []);

    const handlePhoneChange = (e) => {
        const formatPhone = formatPhoneNumber(e.target.value);

        formRef.current.setFieldsValue({
            ownerPhone: formatPhone,
        });
    };

    const onOwnerAddressChange = (value) => {
        // console.log(value.target.value);
    };

    const handleUploadFile = (file, fieldName, index) => {
        let fileFormData = new FormData();
        fileFormData.append("file", file);
        fileFormData.append("_id", application._id);
        fileFormData.append("fieldName", fieldName);
        if (fieldName === "identityDocuments") {
            fileFormData.append(
                "identityDocumentType",
                identityDocuments[index].identityDocumentType
            );
            fileFormData.append("index", index);
        }
        dispatch(uploadApplicationFile(fileFormData));
    };

    const handleRemoveFile = (fieldName, index) => {
        if (
            fieldName === "identityDocuments" &&
            application.identityDocuments[index].identityDocumentType ===
                undefined
        ) {
        } else {
            let formData = new FormData();
            formData.append("_id", application._id);
            formData.append("fieldName", fieldName);
            formData.append("index", index);
            dispatch(removeApplicationFile(formData));

            if (fieldName === "identityDocuments") {
                let tempIdentityDocs = identityDocuments;
                tempIdentityDocs[index].identityDocumentUploadFile = "";
                setIdentityDocuments(tempIdentityDocs);
            }
        }
    };

    const saveForm = () => {
        if (isFormUpdated && application._id) {
            let formData = formRef.current.getFieldsValue();
            formData._id = application._id;
            delete formData.identityDocuments;
            setIsFormUpdated(false);
            dispatch(saveApplication(formData));
        }
    };

    return (
        <Form
            ref={formRef}
            layout="vertical"
            onFinish={saveForm}
            requiredMark="optional"
            initialValues={allFormFields}
            onFieldsChange={() => setIsFormUpdated(true)}
            scrollToFirstError
        >
            <FormButtons
                currentStep={props.currentStep}
                previousStep={props.previousStep}
                nextStep={props.nextStep}
                saveForm={saveForm}
                formRef={formRef}
            />
            <Row justify="space-between" style={{ width: "100%" }}>
                <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                    <Form.Item
                        label="Full Name"
                        name="fullName"
                        required
                        rules={[
                            {
                                required: true,
                                message: "Full name is required.",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Title"
                        name="title"
                        required
                        rules={[
                            {
                                required: true,
                                message: "Title is required.",
                            },
                        ]}
                    >
                        <Input allowClear />
                    </Form.Item>
                    <Form.Item
                        label="Owner Phone"
                        name="ownerPhone"
                        required
                        normalize={(value) => formatPhoneNumber(value)}
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || validatePhoneNumber(value)) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        "Please enter a valid phone number!"
                                    );
                                },
                            }),
                            {
                                required: true,
                                message: "Owner phone is required.",
                            },
                        ]}
                    >
                        <Input
                            onChange={(e) => handlePhoneChange(e)}
                            placeholder="(xxx) xxx-xxxx"
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item
                        label="Owner Address"
                        required
                        onChange={(values) => onOwnerAddressChange(values)}
                    >
                        <Input.Group compact>
                            <Form.Item name={["ownerAddress", "streetAddress"]}>
                                <Input
                                    placeholder="Street Address"
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item name={["ownerAddress", "city"]}>
                                <Input placeholder="City" allowClear />
                            </Form.Item>
                            <Form.Item name={["ownerAddress", "province"]}>
                                <Select
                                    placeholder="Select province"
                                    allowClear
                                >
                                    <Option value="Alberta">Alberta</Option>
                                    <Option value="British Columbia">
                                        British Columbia
                                    </Option>
                                    <Option value="Manitoba">Manitoba</Option>
                                    <Option value="Ontario">Ontario</Option>
                                    <Option value="Quebec">Quebec</Option>
                                    <Option value="Saskatchewan">
                                        Saskatchewan
                                    </Option>
                                    <Option value="Prince Edward Island">
                                        Prince Edward Island
                                    </Option>
                                    <Option value="Nova Scotia">
                                        Nova Scotia
                                    </Option>
                                    <Option value="New Brunswick">
                                        New Brunswick
                                    </Option>
                                    <Option value="Newfoundland and Labrador">
                                        Newfoundland and Labrador
                                    </Option>
                                    <Option value="Northwest Territories">
                                        Northwest Territories
                                    </Option>
                                    <Option value="Yukon">Yukon</Option>
                                    <Option value="Nunavut">Nunavut</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name={["ownerAddress", "postalCode"]}
                                normalize={(value) => value.toUpperCase()}
                            >
                                <Input placeholder="Postal Code" allowClear />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                    <Form.Item label="Date of Birth" required>
                        <Input.Group compact>
                            <Form.Item name="dobDay">
                                <Input placeholder="Day" />
                            </Form.Item>
                            <Form.Item name="dobMonth">
                                <Select
                                    placeholder="Month"
                                    allowClear
                                    style={{ width: 120 }}
                                >
                                    <Option value="January">January</Option>
                                    <Option value="February">February</Option>
                                    <Option value="March">March</Option>
                                    <Option value="April">April</Option>
                                    <Option value="May">May</Option>
                                    <Option value="June">June</Option>
                                    <Option value="July">July</Option>
                                    <Option value="August">August</Option>
                                    <Option value="September">September</Option>
                                    <Option value="October">October</Option>
                                    <Option value="November">November</Option>
                                    <Option value="December">December</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="dobYear">
                                <Input placeholder="Year" />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                    {application.type === "Elavon" && (
                        <Form.Item
                            name="sinNum"
                            label="SIN Number"
                        >
                            <Input placeholder="SIN" />
                        </Form.Item>
                    )}
                    <Form.Item
                        label="Owner Email"
                        name="ownerEmail"
                        required
                        rules={[
                            {
                                required: true,
                                message: "Owner email is required.",
                            },
                            {
                                type: "email",
                                message: "Please enter a valid email address.",
                            },
                        ]}
                    >
                        <Input allowClear />
                    </Form.Item>
                    <Form.Item
                        label="Percentage Owned"
                        name="percentageOwned"
                        required
                        rules={[
                            {
                                required: true,
                                message: "Percentage owned is required.",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                    <Form.List name="identityDocuments">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(
                                    ({ key, name, ...restField }, index) => (
                                        <div key={"header " + key}>
                                            <Row
                                                justify="space-between"
                                                align="middle"
                                            >
                                                <Col>
                                                    <h4>
                                                        Identity Document{" "}
                                                        {index + 1}{" "}
                                                        {index >= 2 && (
                                                            <MinusCircleTwoTone
                                                                twoToneColor="#ff4d4f"
                                                                onClick={() => {
                                                                    remove(
                                                                        name
                                                                    );
                                                                    if (identityDocuments[index]) {
                                                                        handleRemoveFile(
                                                                            "identityDocuments",
                                                                            index
                                                                        );
                                                                    }
                                                                    identityDocuments.splice(
                                                                        index,
                                                                        1
                                                                    );
                                                                    isUploadDisabledArray.splice(
                                                                        index,
                                                                        1
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                    </h4>
                                                </Col>
                                            </Row>
                                            <Row
                                                key={key}
                                                style={{ marginBottom: 20 }}
                                            >
                                                <Col span={12}>
                                                    <Select
                                                        placeholder="Document Type"
                                                        onChange={(value) => {
                                                            let tempIdentityDocs =
                                                                identityDocuments;

                                                            tempIdentityDocs[
                                                                index
                                                            ] = {
                                                                identityDocumentType:
                                                                    value,
                                                            };
                                                            setIdentityDocuments(
                                                                tempIdentityDocs
                                                            );

                                                            let newUploadDisabledArray =
                                                                [
                                                                    ...isUploadDisabledArray,
                                                                ];
                                                            newUploadDisabledArray[
                                                                index
                                                            ] = value
                                                                ? false
                                                                : true;
                                                            setIsUploadDisabledArray(
                                                                newUploadDisabledArray
                                                            );
                                                        }}
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        defaultValue={
                                                            application
                                                                .identityDocuments[
                                                                index
                                                            ] &&
                                                            application
                                                                .identityDocuments[
                                                                index
                                                            ]
                                                                .identityDocumentType &&
                                                            application
                                                                .identityDocuments[
                                                                index
                                                            ]
                                                                .identityDocumentType
                                                        }
                                                    >
                                                        <Option value="Driver's License">
                                                            Driver's License
                                                        </Option>
                                                        <Option value="PR Card">
                                                            PR Card
                                                        </Option>
                                                        <Option value="Passport">
                                                            Passport
                                                        </Option>

                                                        <Option value="Health Card">
                                                            Health Card
                                                        </Option>
                                                    </Select>
                                                </Col>
                                                <Col span={10}>
                                                    <Upload
                                                        key={
                                                            "upload " + { key }
                                                        }
                                                        maxCount={1}
                                                        defaultFileList={
                                                            application
                                                                .identityDocuments[
                                                                index
                                                            ] &&
                                                            application
                                                                .identityDocuments[
                                                                index
                                                            ]
                                                                .identityDocumentUploadFile
                                                                ? [
                                                                      {
                                                                          name: `${application.identityDocuments[index].identityDocumentType} file`,
                                                                          status: "done",
                                                                      },
                                                                  ]
                                                                : []
                                                        }
                                                        onRemove={(file) => {
                                                            handleRemoveFile(
                                                                "identityDocuments",
                                                                index
                                                            );
                                                        }}
                                                        beforeUpload={(
                                                            file
                                                        ) => {
                                                            if (
                                                                file.type ===
                                                                    "image/*" ||
                                                                "application/pdf"
                                                            ) {
                                                                if (
                                                                    identityDocuments[
                                                                        index
                                                                    ]
                                                                        .identityDocumentType !==
                                                                    undefined
                                                                ) {
                                                                    handleUploadFile(
                                                                        file,
                                                                        "identityDocuments",
                                                                        index
                                                                    );
                                                                } else {
                                                                    ErrorMessage(
                                                                        "Please select a document type.",
                                                                        null
                                                                    );
                                                                }
                                                            } else {
                                                                ErrorMessage(
                                                                    "File has to be an image or pdf.",
                                                                    null
                                                                );
                                                            }
                                                            return false;
                                                        }}
                                                    >
                                                        <Button
                                                            htmlType="button"
                                                            icon={
                                                                <UploadOutlined />
                                                            }
                                                            loading={
                                                                subLoading
                                                            }
                                                            disabled={
                                                                subLoading ||
                                                                isUploadDisabledArray[
                                                                    index
                                                                ]
                                                            }
                                                        >
                                                            Upload File
                                                        </Button>
                                                    </Upload>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                )}
                                <Row style={{ margin: "10px 0" }}>
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            add();
                                            let newUploadDisabledArray = [
                                                ...isUploadDisabledArray,
                                            ];
                                            newUploadDisabledArray.push(true);
                                            setIsUploadDisabledArray(
                                                newUploadDisabledArray
                                            );
                                        }}
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                        Add identity document
                                    </Button>
                                </Row>
                            </>
                        )}
                    </Form.List>
                    <Row style={{ margin: "20px 0" }}>
                        <Upload
                            key={"uploadlegalDocument"}
                            maxCount={1}
                            defaultFileList={
                                application.legalDocument && [
                                    {
                                        name: "Legal document file",
                                        status: "done",
                                    },
                                ]
                            }
                            onRemove={(file) => {
                                handleRemoveFile("legalDocument", null);
                            }}
                            beforeUpload={(file) => {
                                if (
                                    file.type === "image/*" ||
                                    "application/pdf"
                                ) {
                                    handleUploadFile(file, "legalDocument");
                                } else {
                                    ErrorMessage(
                                        "File has to be an image or pdf.",
                                        null
                                    );
                                }
                                return false;
                            }}
                        >
                            <Button
                                htmlType="button"
                                icon={<UploadOutlined />}
                                loading={subLoading}
                                disabled={subLoading}
                            >
                                Upload Legal Document
                            </Button>
                        </Upload>
                    </Row>
                    <Row>
                        <Upload
                            key={"uploadCompletedApplication"}
                            maxCount={1}
                            defaultFileList={
                                application.completedApplication && [
                                    {
                                        name: "Completed application file",
                                        status: "done",
                                    },
                                ]
                            }
                            onRemove={(file) => {
                                handleRemoveFile("completedApplication", null);
                            }}
                            beforeUpload={(file) => {
                                if (
                                    file.type === "image/*" ||
                                    "application/pdf"
                                ) {
                                    handleUploadFile(
                                        file,
                                        "completedApplication"
                                    );
                                } else {
                                    ErrorMessage(
                                        "File has to be an image or pdf.",
                                        null
                                    );
                                }
                                return false;
                            }}
                        >
                            <Button
                                htmlType="button"
                                icon={<UploadOutlined />}
                                loading={subLoading}
                                disabled={subLoading}
                            >
                                Upload Completed Application
                            </Button>
                        </Upload>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}
